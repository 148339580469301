import axios from 'axios';
import store from "../store";

const API_URL = store.getters.getApiUrl;

export default {


      async rap0FN(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "finance/rap0FN", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },     
               
      async uniRap(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "finance/report", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async realization(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "finance/targetRealization", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async dashboard(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "finance/dashboard", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async targetEdit(data) {

            const headers = store.getters.getAuthHeader;
            data.syslog = store.getters.userData.userName;

            const response = await axios.post(API_URL + "finance/targetEdit", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async teamList() {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/team", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async teamListA(agencyId) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "admin/team/"+agencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      
     
}