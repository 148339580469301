<template>
  <ball-panel :show="showClock" @close="showClock = !showClock" />

  <base-dialog :show="!!error" title="Something fishy!" @close="handleError">
    <template v-slot:content>
      <p>
        {{ this.error }}
      </p>
    </template>
  </base-dialog>

  <Teleport to="body">
    <div :class="['copied-cld', showCopied ? 'copied-active':'copied-inactive' ]" v-bind:style="{ top: modalTopCopied, left: modalLeftCopied }">{{copiedHintText}}</div>
  </Teleport>


  <base-dialog :windowWidth="890" :show="showEventCreationDialog" :title="baseDialogTitle" @close="cancelEventCreation()">
     <template v-slot:content>
      <div class="s-label">
        <span v-if="selectedEvent.noJob">Job description</span>
        <span v-else>Job number <a class="info-job-btn" v-show="agencyId != 1" href="/pdf/Joby-struktura.pdf" target="_blank"><i class="fas fa-info"></i></a></span>
        <div class="no-job-wrapper light-text">
          <div class="info-text">unknown Job number</div>
          <!-- <input type="checkbox" name="noJob" v-model="selectedEvent.noJob"/> -->
          <label class="rad-label">
                <input type="checkbox" class="rad-input" name="noJob" v-model="selectedEvent.noJob" />
                <div class="rad-design"></div>
         </label>
        </div>

      </div>

      <div v-if="selectedEvent.noJob">
        <div class="no-job-form-wrapper">
          <textarea placeholder="description of the work performed" v-model="selectedEvent.noJobDescr"></textarea>
          <vue-select
            v-model="selectedEvent.noJobUserId"
            :options="usersListGet()"
            label-by="Name"
            value-by="Id"
            placeholder="select a responsible person"
            searchable
            clear-on-select
            clear-on-close
            close-on-select
          >
              <template #dropdown-item="{ option }">
                <div :class="'st-'+option.Status">{{ option.Name }}</div>
              </template>
          </vue-select>

        </div>
        <div v-if="!showNewEventCreationDialog" class="light-text">
          <!-- <input type="checkbox" name="changeAll" v-model="noJobChangeAll"/> change all entries with this description -->
          <div class="no-job-wrapper light-text">
            <label class="rad-label left-label">
                  <input type="checkbox" class="rad-input" name="changeAll" v-model="noJobChangeAll" />
                  <div class="rad-design"></div>
            </label>
            <div class="info-text">change all entries with this description</div>
          </div>
        </div>
      </div>

      <div v-else>

        <div v-if="jobExistCheck(selectedEvent.jobId) || showNewEventCreationDialog">
          <vue-select
            v-model="selectedEvent.jobId"
            :options="jobs"
            label-by="jobNoName"
            value-by="jobId"
            placeholder="select Job number"
            searchable
            clear-on-select
            clear-on-close
            close-on-select
            ref="newEntry"
          >
                <template #dropdown-item="{ option }">
                  <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
                </template>

          </vue-select>
          <p class="job-descr-inf">{{ jobDescr(selectedEvent.jobId) }}</p>
        </div>
        <div v-else class="job-expired">
          <p>{{ selectedEvent.job }}<br/><span>/expired/</span></p>
        </div>

      </div>

      <div :class="['s-picker-wrapper', {'narrower': selectedEvent.noJob && !showNewEventCreationDialog} ]">

        <div>
          <span>Start time</span>
          <!-- <scroll-picker
            :options="hoursArr"
            v-model="selectedEvent.startTime">
          </scroll-picker> -->
            <div class="time-btn-wrapper">
              <button class="time-btn" @click="periodTimePicker(-30,'start')"><i class="fas fa-chevron-left"></i></button>
              <date-picker v-model="selectedEvent.startTime"
                  minutesIncrement="30"
                  timePicker
                  autoApply
                  :teleport="true"
                  minutesGridIncrement="30"
                  modeHeight="180"
                  :dark="settDarkMode"
                  :startTime="selectedEvent.startTime"
                  :autoPosition="false"
                  :clearable="false"
                  inputClassName="dp-custom-input"
                  menuClassName="dp-custom-menu"
                  @internalModelChange="timeChecker()"
                  :filters="{times:{hours:[0,1,2,3,4,5,21,22,23]}}"
                  :minTime="{ hours: 6, minutes: 0 }"
                  :maxTime="{ hours: 20, minutes: 30 }">

                <template #input-icon>
                    &nbsp;&nbsp;<i class="far fa-clock"></i>
                </template>

              </date-picker>
              <button class="time-btn" @click="periodTimePicker(30,'start')"><i class="fas fa-chevron-right"></i></button>
            </div>
        </div>

        <div>
          <span>End time</span>
          <!-- <scroll-picker
            :options="hoursArr"
            v-model="selectedEvent.endTime">
          </scroll-picker> -->
            <div class="time-btn-wrapper">
              <button class="time-btn" @click="periodTimePicker(-30,'end')"><i class="fas fa-chevron-left"></i></button>
              <date-picker v-model="selectedEvent.endTime"
                  minutesIncrement="30"
                  timePicker
                  autoApply
                  :teleport="true"
                  minutesGridIncrement="30"
                  modeHeight="180"
                  :dark="settDarkMode"
                  :startTime="selectedEvent.endTime"
                  :autoPosition="false"
                  :clearable="false"
                  inputClassName="dp-custom-input"
                  menuClassName="dp-custom-menu"
                  @internalModelChange="timeChecker()"
                  :minTime="{ hours: 6, minutes: 0 }"
                  :maxTime="{ hours: 20, minutes: 30 }" >

                <template #input-icon>
                    &nbsp;&nbsp;<i class="far fa-clock"></i>
                </template>
              </date-picker>
              <button class="time-btn" @click="periodTimePicker(30,'end')"><i class="fas fa-chevron-right"></i></button>
            </div>
        </div>
      </div>
      <div v-if="showNewEventCreationDialog" class="repeat-wrap">
        <div class="repeat-content" >
          <span>Repeat in next</span>
          <vue-select v-model="selectedRepeatDay" :options="repeatDays" label-by="value" value-by="lp" clear-on-select clear-on-close close-on-select />
        </div>
        <div class="repeat-content" >
          <span>Every {{getDayName2(selectedDate)}} for</span>
          <vue-select v-model="selectedRepeatWeek" :options="repeatWeeks" label-by="value" value-by="lp" clear-on-select clear-on-close close-on-select />
        </div>
      </div>

      <div class="valid-content">
          <span>
             {{ warningMessageCreationDialog }}
             <!--{{ selectedEvent.noJobDescr }}<br> -->
          </span>
      </div>
    </template>

    <template v-slot:actions>
      <base-button class="d-btn d-edit" @click="cancelEventCreation()" title="Cancel"><i class="fas fa-times"></i></base-button>
      <base-button class="d-btn d-edit" @click="closeCreationDialog()"
          title="Save"
          :disabled="warningMessageCreationDialog !=='' || checkDisable(selectedEvent.noJob, selectedEvent.jobId, selectedEvent.noJobUserId, selectedEvent.noJobDescr)"
      ><i class="fas fa-save"></i></base-button>
    </template>
  </base-dialog>

  <transition name="fade">
    <div v-if="showPropertyDialog" class="dialog-box info" v-bind:style="{ top: modalTop, left: modalLeft }">

      <entry-descr :noTitle="false" :title="selectedEvent.jobNo" :leftSide="propertyDialogLeftSide" @closePropertyDialog="closePropertyDialog()" >
          <template v-slot:modalBody>
            <div class="vuecal__event-title job-name" v-html="selectedEvent.jobName"  />
              <div class="vuecal__event-title job-client" v-html="selectedEvent.client" />

              <div class="vuecal__event-time">
                <div class="job-time">
                  <button class="i-btn" @click="onEventDblclick(selectedEvent)" title="Edit"><i class="fas fa-hourglass-start"></i></button>
                  <span><strong>{{ selectedEvent.start.formatTime("HH:mm") }} - {{ selectedEvent.end.formatTime("HH:mm") }}</strong></span>
                </div>
                <!-- <div class="job-time">
                  <i class="fas fa-hourglass-end"></i>&nbsp;&nbsp;<span>{{ selectedEvent.start.format("YYYY-MM-DD") }} <strong>{{ selectedEvent.end.formatTime("HH:mm") }}</strong></span>
                </div> -->
              </div>
              <div class="d-buttons">
                <button class="d-btn" @click="onEventDelete(selectedEvent, true)" title="Delete"><i class="fas fa-trash"></i></button>
                <button class="d-btn" @click="onEventDblclick(selectedEvent)" title="Edit"><i class="fas fa-edit"></i></button>
              </div>
          </template>
      </entry-descr>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="showContextMenu" class="dialog-box context-menu" v-bind:style="{ top: modalContextTop, left: modalContextLeft }">

      <entry-descr :noTitle="true" title="NEW ENTRY" :leftSide="contextMenuLeftSide" @closePropertyDialog="closeContextMenu()" >
          <template v-slot:modalBody>
            <div class="btn-wrapper">
              <div class="btn-pos">
                <button class="m-btn" @click="ContextMenuNew(selectedDate, 120)" title="Add Entry"><i class="fas fa-plus-circle"></i>&nbsp;&nbsp;New</button>
              </div>
              <div v-if="copiedEvent.id !== 0" class="btn-pos">
                <button class="m-btn" @click="onPaste(selectedDate)" title="Add Entry"><i class="far fa-clone"></i>&nbsp;&nbsp;Paste <span>{{copiedEvent.jobNo}}</span></button>
              </div>
            </div>


          </template>
      </entry-descr>
    </div>
  </transition>


    <nav-header :showNav="showNav" :clearButtons="false">
      <template v-slot:navButtons>
          <button class="nav-btn nav-cmp" @click="hideMinCal = !hideMinCal">
            <i :class="['fas', hideMinCal ? 'fa-expand-alt':'fa-compress-alt']"></i>
          </button>
          <button class="nav-btn" @click="selectedDate = new Date()">Today</button>
          <button class="nav-btn" @click="previous()"><i class="angle prev"></i></button>
          <button class="nav-btn" @click="next()"><i class="angle"></i></button>
      </template>
      <template v-slot:navDate>
          <span v-if="activeView === 'years'">Years</span>
          <span v-else-if="activeView === 'year'">Year {{ selectedDate.format("YYYY") }}</span>
          <span v-else-if="activeView === 'month'">{{ getMonthYear(selectedDate) }}</span>
          <span v-else-if="activeView === 'week'"> Week {{ selectedDate.getWeek() }}, {{ getMonthYear(selectedDate) }} </span>
          <span :class="selectedDate.format('ddd')" v-else-if="activeView === 'day'">{{ getFullTxtDate(selectedDate) }}</span>
      </template>
      <template v-slot:navView>
        <button v-if="userNoJobCount !== 0" class="ring-btn" @click="goToRouter('MissingJobs')">
          <i class="far fa-bell"></i>
          <div class="ring-info">{{ this.userNoJobCount }}</div>
        </button>
        <button class="nav-btn" :class="{'view-active': activeView === 'day'}" @click="activeView = 'day'">Day</button>
        <button class="nav-btn" :class="{'view-active': activeView === 'week'}" @click="activeView = 'week'">Week</button>
        <button class="nav-btn" :class="{'view-active': activeView === 'month'}" @click="activeView = 'month'">Month</button>
        <button class="nav-btn" :class="{'view-active': activeView === 'year'}" @click="activeView = 'year'">Year</button>
      </template>
      <template v-slot:navLog>
        <!-- <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'far':'fas', 'fa-user']"></i></button> -->
        <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'fa-ellipsis-v':'fa-ellipsis-h', 'fas']"></i></button>
      </template>
    </nav-header>



  <div :class="['wrap', {'wrap-cal': this.activeView !== 'year'}, {'color-blind': settColorBlind}]" ref="wrapDiv" id="wrapDiv" >
    <div class="mx-2" :class="hideMinCal ? 'hide-cal':'show-cal'">

      <vue-cal
        ref="svuecal"
        class="vuecal--date-picker demo"
        xsmall="xsmall"
        locale="en"
        :selected-date="selectedDate"
        :eventsCountOnYearView="true"
        hide-view-selector="hide-view-selector"
        :time="false"
        :transitions="false"
        active-view="month"
        :disable-views="['week', 'day']"
        :editable-events="{ title: false, drag: false, resize: false, delete: false, create: false }"
        @cell-click="onCellClick($event)"
        style="width: 240px; height: 290px"
        :events="sEventsDataSum"
        @ready="sOnEventReady"
        @view-change="sOnViewChange($event)"
      >
        <template v-slot:cell-content="{ cell, view, events }">
          <span :class="['vuecal__cell-date', {'holi-date': sholiTest(cell.formattedDate) && view.id === 'month'}]">{{ cell.content }}</span>
          <span class="vuecal__cell-events-count" :class="eventCountClass(timeToMin(sEventsCount(events)))" v-if="['years', 'year', 'month'].includes(view.id) && sEventsCount(events)">

            {{ sEventsCount(events) }}
          </span>
        </template>
        <template v-slot:weekday-heading="{ heading, view }">
          <div v-if="view.id === 'month'" style="width: 100%; cursor: initial;">
            <span class="full">{{ heading.label }} </span>
            <span class="small">{{ heading.label.substr(0, 3) }}</span>
            <span class="xsmall">{{ heading.label.substr(0, 2) }}</span>
          </div>
          <div v-else style="width: 100%;" @click="goToDayView(heading.date)">
            <span class="full">{{ heading.label }} </span>
            <span class="small">{{ heading.label.substr(0, 3) }}</span>
            <span class="xsmall">{{ heading.label.substr(0, 2) }}</span>
          </div>
          
        </template>

      </vue-cal>

      <!-- <div class="grey--text code my-2" style="font-size: 13px">Selected date: '{{ selectedDate.format() }}'</div> -->
      <button v-show="agencyId != 1" class="d-lnk b-ru-jobs" @click="recentlyJobsShow = !recentlyJobsShow" :title="recentlyJobsShow ? 'Show non-client Jobs' : 'Show recently used Jobs'">
          {{recentlyJobsShow ? 'Non-client' : 'Recently used'}}
        </button>

      <div class="ru-title">          
          {{recentlyJobsShow ? 'Recently used job numbers' : 'Non-client job numbers'}}
        </div>
      <div class="ru-wrapper" :style="{ height: this.rcContainerHeight + 'px' }">    
        
        <div :class="['ru-jobs', {'ru-tiny': this.windowHeight < 850}]"  title="Just drag end drop it on the calendar">
            <div v-for="(item, i) in recentJobs" :key="i" :class="['ru-jobs-event',item.class]"
              draggable="true"
              @dragstart="onEventDragStart($event, item)">

              <strong>{{ item.jobNo }}</strong>
              <div>{{ item.jobName }}</div>
              <div>{{ item.clientName }}</div>
            </div>
        </div>
        <!-- {{testKey}} <br>
        {{selectedEvent.id}} -->
              <!-- {{ selectedDate }} <br> -->


      </div>

    </div>
    <div class="mx-10">
      <div v-if="activeView==='week'" class="refr-cal" title="Refresh the calendar">
          <button class="i-btn" @click="reloadPage">
            <i class="fas fa-sync-alt"></i>
          </button>
      </div>

      <vue-cal
        :key="componentKey"
        class="main-cal"
        ref="vuecal"
        locale="en"
        :cell-contextmenu="true"
        :selected-date="selectedDate"
        :active-view="activeView"
        :eventsCountOnYearView="true"
        :time-from="setHoursRange.from * 60"
        :time-step="30"
        :time-cell-height="timeCellHeight"
        :time-to="setHoursRange.to * 60"
        time-format="HH:mm"
        :disable-views="['years']"
        :cell-click-hold="false"
        :dblclick-to-navigate="dblclickToNavigate"
        :snap-to-time="30"
        :editable-events="eventsSettings"
        :on-event-create="onEventCreate"
        :on-event-click="onEventClick"
        :on-event-dblclick="onEventDblclick"
        :showWeekNumbers="true"
        events-on-month-view="short"
        @cell-click="onCellClick($event)"
        @cell-dblclick="onCellDbClick($event)"
        @cell-contextmenu="onCellcontextMenu($event)"
        @event-drag-create="onEventDragCreate"
        @ready="onEventReady"
        @view-change="onViewChange($event)"
        @event-duration-change="onEventDurationChange"
        @event-drop="onEventDrop"
        @event-delete="onEventDelete"
        :events="events"
        :hideTitleBar="activeView==='week'||activeView==='month'"
        :hideViewSelector="true"
        :min-event-width="100"
        :hide-weekends="!settShowWeekends"


      >


        <template v-slot:cell-content="{ cell, view, events }">
          <div :class="['wrap_cell-date', {'holi-date': holiTest(cell.formattedDate) && (view.id === 'month' || view.id === 'week')}]">
            <span class="'vuecal__cell-date'">{{ cell.content }}</span>

            <span class="vuecal__cell-events-count" v-if="['year'].includes(view.id) && eventsCount(events)">
              {{ eventsCount(events, cell) }}
            </span>

          </div>
        </template>

        <template v-slot:title="{ view }">
          <span v-if="view.id === 'years'">Years</span>
          <span v-else-if="view.id === 'year'">{{ view.startDate.format("YYYY") }}</span>
          <span v-else-if="view.id === 'month'">{{ view.startDate.format("MMMM YYYY") }}</span>
          <span v-else-if="view.id === 'week'"> Week2 {{ view.startDate.getWeek() }}, {{ getMonthYear(view.startDate) }} </span>
          <!-- <span :class="view.startDate.format('ddd')" v-else-if="view.id === 'day'"> {{ view.startDate.format("dddd D MMMM (YYYY)") }}</span> -->
          <div :class="view.startDate.format('ddd')" v-else-if="view.id === 'day'">
              <span> {{ getDayName2(view.startDate) }}</span>
              <span class="day-no"> {{ view.startDate.format("D") }}</span>
          </div>
        </template>

        <!-- weekday-heading w widoku tygodniowym dodanie przycisku który przechodzi do szczegółów danego dnia-->
        <template v-slot:weekday-heading="{ heading, view }">
          <div v-if="view.id === 'month'" style="width: 100%; cursor: initial;">
            <span class="full">{{ heading.label }} </span>
            <span class="small">{{ heading.label.substr(0, 3) }}</span>
            <span class="xsmall">{{ heading.label.substr(0, 1) }}</span>
          </div>
          <div v-else style="width: 100%;" @click="goToDayView(heading.date)" :class="{'holi-label': holiTest(heading.date.format('YYYY-MM-DD')) && (view.id === 'day' || view.id === 'week')}">
            <span class="full">{{ heading.label }} </span>
            <span class="small">{{ heading.label.substr(0, 3) }}</span>
            <span class="xsmall">{{ heading.label.substr(0, 1) }}</span>
            <span class="weekday-no">{{ heading.date.getDate() }}</span>
            <div v-if="view.id === 'week'" class="ho-wrap">
              <button :ref="'hobtno'+heading.date.format('YYYY-MM-DD').replaceAll('-', '')" @click.stop="setHO(heading.date.format('YYYY-MM-DD'), 'o')" :class="['ho-btn ho-office', checkHoDateAndType(heading.date.format('YYYY-MM-DD'),'o') ? 'ho-active':'' ]">Office</button>
              <button :ref="'hobtnh'+heading.date.format('YYYY-MM-DD').replaceAll('-', '')" @click.stop="setHO(heading.date.format('YYYY-MM-DD'), 'h')" :class="['ho-btn ho-home', checkHoDateAndType(heading.date.format('YYYY-MM-DD'),'h') ? 'ho-active':'' ]">Home</button>
            </div>            
          </div>
          
        </template>


          <template v-slot:time-cell="{ hours, minutes }">
            <div :class="{ 'vuecal__time-cell-line': true, hours: !minutes }">
              <span v-if="!minutes" style="font-size: 12px; padding-right: 2px;">{{ hours }}:00</span>
              <!-- <span v-else style="font-size: 11px">{{ minutes }}</span> -->
            </div>
          </template>


        <!-- widok nowy - z nazwą klienta na górze -->
        <template v-if="!settOldViewEntry" v-slot:event="{event}">

          <div v-if="this.activeView === 'month'" :class="['event-wrap', this.activeView]">
            <div class="icons">
              <button class="i-btn" @click="onEventCopy(event, $event)" title="Copy"><i :class="['fa-copy', event.id === copiedEvent.id ? 'fas':'far']"></i></button>
              <button class="i-btn" @click="onEventDelete(event, true)" title="Delete"><i class="fas fa-trash"></i></button>
              <button class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-edit"></i></button>
            </div>
            <div class="job-title">
              <div class="vuecal__event-title job-no" v-bind:title="event.client+' - '+event.jobName+' ('+event.id+')'" v-html="event.jobNo" />
            </div>
          </div>
          <div v-else :class="['event-wrap', this.activeView, {'event-drag-h': testKey }]">
            <div class="brd"></div>
            <div class="icons">
              <button class="i-btn" @click="onEventCopy(event, $event)" title="Copy"><i :class="['fa-copy', event.id === copiedEvent.id ? 'fas':'far']"></i></button>
              <button class="i-btn" @click="onEventDelete(event, true)" title="Delete"><i class="fas fa-trash"></i></button>
              <button class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-edit"></i></button>
            </div>
            <div class="job-title">
              <div class="vuecal__event-title job-no" v-bind:title="event.client+' - '+event.jobName+' ('+event.id+')'" v-html="event.jobNo" />
            </div>


            <div v-if="this.activeView !== 'year'" class="vuecal__event-title job-name job-name-s" v-html="event.jobName" />
            <div class="vuecal__event-title job-client" v-html="event.client" />
            <div v-if="this.activeView == 'week'" class="job-clientShort" v-bind:title="event.jobNo+' - '+event.jobName+' - '+event.client" v-html="event.clientShort" />
            <div v-if="this.activeView == 'day'" class="job-clientDay" v-bind:title="event.jobNo+' - '+event.jobName+' - '+event.client" v-html="event.client" />
            <!-- Or if your events are editable: -->
            <!-- <div class="vuecal__event-title vuecal__event-title--edit" contenteditable @blur="event.job = $event.target.innerHTML" v-html="event.job" /> -->
            <!-- <div class="vuecal__event-title" v-html="event.content" /> -->

            <small class="vuecal__event-time">

              <div class="job-time">
               <button class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-hourglass-start"></i></button>
               <span><strong>{{ event.start.formatTime("HH:mm") }}</strong></span> - <span><strong>{{ event.end.formatTime("HH:mm") }}</strong></span>
              </div>

              <!-- <div class="job-time">
               <i class="fas fa-hourglass-end"></i>&nbsp;&nbsp;<span>{{ event.start.format("YYYY-MM-DD") }} <strong>{{ event.end.formatTime("HH:mm") }}</strong></span>
              </div> -->
            </small>

            <div :class="[{'e-time-show':timeToMin(event.time) >= 120}, 'e-time']">{{ minToTime(event.endTimeMinutes-event.startTimeMinutes) }} </div>
            <!-- <div :class="[{'e-time-show':timeToMin(event.time) >= 120}, 'e-time']" v-html="event.client" />  -->
          </div>


          <div v-if="this.activeView === 'week'" :class="['event-wrap', this.activeView, 'event-drag', {'event-drag-v': testKey }, {'drag-state': dragState}]"
            @dragleave="onDragOver"
            draggable="true"
            @dragstart="onEventDragStart($event, event)">


            <div class="icons">
              <button disabled class="i-btn" @click="onEventCopy(event)" title="Copy"><i :class="['fa-copy', event.id === copiedEvent.id ? 'fas':'far']"></i></button>
              <button disabled class="i-btn" @click="onEventDelete(event, true)" title="Delete"><i class="fas fa-trash"></i></button>
              <button disabled class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-edit"></i></button>
            </div>
            <div class="job-title">
              <div class="vuecal__event-title job-no" v-bind:title="event.id" v-html="event.jobNo" />
            </div>
            <div class="vuecal__event-title job-name job-name-s" v-html="event.jobName"  />
            <div class="vuecal__event-title job-client" v-html="event.client" />
            <div v-if="this.activeView == 'week'" class="job-clientShort" v-html="event.clientShort"  />


            <small class="vuecal__event-time">
              <div class="job-time">
                <!-- <i class="fas fa-hourglass-start"></i>&nbsp;&nbsp;<span>{{ event.start.format("YYYY-MM-DD") }} <strong>{{ event.start.formatTime("HH:mm") }}</strong></span> -->
                <button disabled class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-hourglass-start"></i></button>
               <span><strong>{{ event.start.formatTime("HH:mm") }}</strong></span> - <span><strong>{{ event.end.formatTime("HH:mm") }}</strong></span>
              </div>
            </small>

            <div :class="[{'e-time-show':timeToMin(event.time) >= 120}, 'e-time']">{{ event.time }}</div>
          </div>

        </template>

        <!-- widok stary - z JOb No na górze -->
        <template v-else v-slot:event="{event}">

          <div v-if="this.activeView === 'month'" :class="['event-wrap', 'old-view', this.activeView]">
            <div class="icons">
              <button class="i-btn" @click="onEventCopy(event, $event)" title="Copy"><i :class="['fa-copy', event.id === copiedEvent.id ? 'fas':'far']"></i></button>
              <button class="i-btn" @click="onEventDelete(event, true)" title="Delete"><i class="fas fa-trash"></i></button>
              <button class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-edit"></i></button>
            </div>
            <div class="job-title">
              <div class="vuecal__event-title job-no" v-bind:title="event.client+' - '+event.jobName+' ('+event.id+')'" v-html="event.jobNo" />
            </div>
          </div>
          <div v-else :class="['event-wrap', 'old-view', this.activeView, {'event-drag-h': testKey }]">
            <div class="brd"></div>
            <div class="icons">
              <button class="i-btn" @click="onEventCopy(event, $event)" title="Copy"><i :class="['fa-copy', event.id === copiedEvent.id ? 'fas':'far']"></i></button>
              <button class="i-btn" @click="onEventDelete(event, true)" title="Delete"><i class="fas fa-trash"></i></button>
              <button class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-edit"></i></button>
            </div>
            <div class="job-title">
              <div class="vuecal__event-title job-no" v-bind:title="event.client+' - '+event.jobName+' ('+event.id+')'" v-html="event.jobNo" />
            </div>


            <div v-if="this.activeView !== 'year'" class="vuecal__event-title job-name job-name-s" v-html="event.jobName" />
            <div class="vuecal__event-title job-client" v-html="event.client" />

            <small class="vuecal__event-time">

              <div class="job-time">
               <button class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-hourglass-start"></i></button>
               <span><strong>{{ event.start.formatTime("HH:mm") }}</strong></span> - <span><strong>{{ event.end.formatTime("HH:mm") }}</strong></span>
              </div>

            </small>

            <div :class="[{'e-time-show':timeToMin(event.time) >= 120}, 'e-time']">{{ minToTime(event.endTimeMinutes-event.startTimeMinutes) }} </div>
          </div>


          <div v-if="this.activeView === 'week'" :class="['event-wrap', 'old-view', this.activeView, 'event-drag', {'event-drag-v': testKey }, {'drag-state': dragState}]"
            @dragleave="onDragOver"
            draggable="true"
            @dragstart="onEventDragStart($event, event)">


            <div class="icons">
              <button disabled class="i-btn" @click="onEventCopy(event)" title="Copy"><i :class="['fa-copy', event.id === copiedEvent.id ? 'fas':'far']"></i></button>
              <button disabled class="i-btn" @click="onEventDelete(event, true)" title="Delete"><i class="fas fa-trash"></i></button>
              <button disabled class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-edit"></i></button>
            </div>
            <div class="job-title">
              <div class="vuecal__event-title job-no" v-bind:title="event.id" v-html="event.jobNo" />
            </div>
            <div class="vuecal__event-title job-name job-name-s" v-html="event.jobName"  />
            <div class="vuecal__event-title job-client" v-html="event.client" />

            <small class="vuecal__event-time">
              <div class="job-time">
                <!-- <i class="fas fa-hourglass-start"></i>&nbsp;&nbsp;<span>{{ event.start.format("YYYY-MM-DD") }} <strong>{{ event.start.formatTime("HH:mm") }}</strong></span> -->
                <button disabled class="i-btn" @click="onEventDblclick(event)" title="Edit"><i class="fas fa-hourglass-start"></i></button>
               <span><strong>{{ event.start.formatTime("HH:mm") }}</strong></span> - <span><strong>{{ event.end.formatTime("HH:mm") }}</strong></span>
              </div>
            </small>

            <div :class="[{'e-time-show':timeToMin(event.time) >= 120}, 'e-time']">{{ event.time }}</div>
          </div>

        </template>

      </vue-cal>

      <div v-if="this.activeView === 'day'" class="total-wrapper">
        {{dataEventCountDay[0].sum}}
      </div>

      <div v-if="this.activeView === 'week'" class="total-wrapper">
        <div class="total-week plug-left">
        </div>
        <div class="inner-total" v-for="item in dataEventCountDay" :key="item.date">
            {{item.sum}}
        </div>
        <div class="total-week">
           {{this.minToTime(dataEventCountTotalWeek)}}
        </div>


      </div>

    </div>

    <nav-menu :showMenu="showMenu" ></nav-menu>
  </div>


</template>




<script>
import entiresService from "@/services/entries.js";
import jobsService from "@/services/jobs.js";
import userService from "@/services/user.js";
import settingsService from "@/services/settings.js";
import shared from "@/shared";
import moment from "moment";
//import store from "../store";
//import { ref } from 'vue';
//import entryDescr from './ui/entryDescr.vue';
//import jHour from '@/assets/hour.json';
//import jMinute from '@/assets/minute.json';

export default {
  components: { },
  name: "App",

  data: function () {
    return {
      jobs: [],
      sEventsDataSum: [],
      sDataFrom: null,
      sDataTo: null,
      sActiveView: "month",
      sholiday: [],
      events: [],
      selectedDate: new Date(),
      activeView: "week",
      selectedEvent: {},
      copiedEvent: {id:0},
      copiedHintText: "Copied",
      showCopied: false,
      showEventCreationDialog: false,
      showNewEventCreationDialog: false,
      warningMessageCreationDialog: '',
      baseDialogTitle: 'New entry',
      dblclickToNavigate: false,
      error: null,
      showPropertyDialog: false,
      propertyDialogLeftSide: false,
      showContextMenu: false,
      contextMenuLeftSide: false,
      modalTop: 0,
      modalLeft: 0,
      modalContextTop: 0,
      modalContextLeft: 0,
      modalTopCopied: 0,
      modalLeftCopied: 0,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      rcContainerHeight: 0,
      hideMinCal: false,
      showMenu: false,
      deleteEventFunction: null,
      repeatDays: [
                    {lp: 0, value: "No repeat"},
                    {lp: 1, value: "1 Day"},
                    {lp: 2, value: "2 Days"},
                    {lp: 3, value: "3 Days"},
                    {lp: 4, value: "4 Days"},
                    {lp: 5, value: "5 Days"},
                    {lp: 6, value: "6 Days"},
                    {lp: 7, value: "7 Days"},
                    {lp: 8, value: "8 Days"}
                  ],
      repeatWeeks: [
                    {lp: 0, value: "No repeat"},
                    {lp: 1, value: "1 Week "},
                    {lp: 2, value: "2 Weeks"},
                    {lp: 3, value: "3 Weeks"},
                    {lp: 4, value: "4 Weeks"},
                    {lp: 5, value: "5 Weeks"},
                    {lp: 6, value: "6 Weeks"},
                    {lp: 7, value: "7 Weeks"},
                    {lp: 8, value: "8 Weeks"}
                  ],

      // specialHours: {
      //                 1: {from: 9 * 60, to: 17 * 60, class: 'business-hours'},
      //                 2: {from: 9 * 60, to: 17 * 60, class: 'business-hours'},
      //                 3: {from: 9 * 60, to: 17 * 60, class: 'business-hours'},
      //                 4: {from: 9 * 60, to: 17 * 60, class: 'business-hours'},
      //                 5: {from: 9 * 60, to: 17 * 60, class: 'business-hours'}
      //               },
      selectedRepeatDay: 0,
      selectedRepeatWeek: 0,
      showNav: false,
      //hoursArr: [jHour, jMinute],
      recentJobs: [],
      recentlyJobsShow: true,
      settShowWeekends: true,
      settColorBlind: false,
      settDropDefTime: '02:00',
      settDarkMode: false,
      setHoursRange: {from: 6, to: 20},
      settOldViewEntry: false,
      timeCellHeight: 24,
      eventsSettings: { title: false, drag: true, resize: true, delete: false, create: true },
      testKey: false,
      dragState: false,
      usersList: [],
      userNoJobCount: 0,
      noJobChangeAll: false,
      dataEventCountDay: [],
      dataEventCountTotalWeek: 0,
      showClock: false,
      holiday: [],
      currYear: null,
      agencyId: this.$store.getters.userData.agencyId,
      componentKey: 0,
      hoList: [],
      listeners:  {}

    };
  },
  created() {
    this.noToTime = shared.noToTime;
    this.minToTime = shared.minToTime;
    this.minToTimeObj = shared.minToTimeObj;
    this.timeToMin = shared.timeToMin;
    this.CookieRemove = shared.CookieRemove;
    this.getSettingFromArr = shared.getSettingFromArr;
    this.goToRouter = shared.goToRouter;
    this.getDaysArray = shared.getDaysArray;
    //this.getDayName = shared.getDayName;
    this.setTheme = shared.setTheme;
    this.getMonthYear = shared.getMonthYear;
    this.getFullTxtDate = shared.getFullTxtDate;
    this.getDayName2 = shared.getDayName2;

    this.settShowWeekends = this.getSettingFromArr(this.$store.getters.userSettings, 1, true);
    this.hideMinCal = !this.getSettingFromArr(this.$store.getters.userSettings, 2, true);
    this.settDropDefTime = this.getSettingFromArr(this.$store.getters.userSettings, 3, false);
    this.activeView = this.getSettingFromArr(this.$store.getters.userSettings, 4, false);
    this.settColorBlind = this.getSettingFromArr(this.$store.getters.userSettings, 5, true);
    this.setHoursRange = JSON.parse(this.getSettingFromArr(this.$store.getters.userSettings, 6, false));
    this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
    this.settOldViewEntry = this.getSettingFromArr(this.$store.getters.userSettings, 9, true) || 0;

    this.currYear = this.$store.getters.getCurrYear;

    this.fitTimeCellHeight();

  },
  mounted() {


    // if (!window.hasEventListener('resize')) {
    //    window.addEventListener('resize', this.windowResize, { passive: true});
    // }

    // if (!window.hasEventListener('keydown')) {
    //    window.addEventListener('keydown', this.testDown, { passive: true});
    // }

    // if (!window.hasEventListener('keyup')) {
    //    window.addEventListener('keyup', this.testUp, { passive: true});
    // }

    var isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
        document.body.classList.add('firefox');
    }


    this.rcContainerHeight = this.windowHeight - 450;
    if (this.windowHeight < 850) {
            this.rcContainerHeight = this.windowHeight - 400;
    }
   

    window.addEventListener('resize', this.windowResize, { passive: true});
    window.addEventListener('keydown', this.testDown, { passive: true});
    window.addEventListener('keyup', this.testUp, { passive: true});

    // this.addListener('resize', this.windowResize);
    // this.addListener('keydown', this.testDown);
    // this.addListener('keyup', this.testUp);

    //console.log('w: '+window.innerWidth+' h: '+window.innerHeight);

    // console.log('this.listeners');
    // console.log(this.listeners);

    this.setTheme(this.settDarkMode);



    jobsService
      .jobs()
      .then((response) => {

        if (response.status === 200) {

          this.jobs = response.data;

            //console.log("----this.jobs----");
            //console.log(this.jobs);

        }

      })
      .catch((e) => shared.handleError.call(this, '010', true, e));


    userService
      .usersList()
      .then((response) => {

        if (response.status === 200) {
          this.usersList = response.data;
          // console.log("----user list----");
          // console.log(this.usersList);
        }

      })
      .catch((e) => shared.handleError.call(this, '011', true, e));



    this.recentlyJobsRefresh();
    this.selectedEventClear();
    this.noJobCountRefresh();

    setTimeout(() => {
        this.showNav = true;
      }, 600);




  },

  beforeUnmount() {
            window.removeEventListener('resize', this.windowResize, { passive: true});
            window.removeEventListener('keydown', this.testDown, { passive: true});
            window.removeEventListener('keyup', this.testUp, { passive: true});
        },

  computed: {

  },

  watch: {

    recentlyJobsShow: {
      handler: function () {

        this.recentlyJobsRefresh(); 

      }
    }


  },


  methods: {

    // addListener(event, callback) {
    //   if (!this.listeners[event]) {
    //     this.listeners[event] = new Set();
    //   }

    //   if (!this.listeners[event].has(callback)) {
    //     window.addEventListener(event, callback, { passive: true});
    //     this.listeners[event].add(callback);
    //   }
    // },

    reloadPage() {
      //location.reload();
      //this.$forceUpdate();

      jobsService
      .jobs()
      .then((response) => {

        if (response.status === 200) {

          this.jobs = response.data;
          this.componentKey += 1;
        }

      })
      .catch((e) => shared.handleError.call(this, '012', true, e));

    },

    setHO(date, type) {
      
       //console.log("date");
       //console.log(date);
       //console.log("type: "+type);

        const data = {
          Date: date,
          Type: type
        };

        entiresService
        .hoSet(data)
        .then((response) => {
          //console.dir(response);
          if (response.status === 200) {
            if (response.data.Code === "OK") {

              //console.log("ho set");

              let dateW = date.replaceAll('-', '');

              //console.log(dateW);

              const refNameO = `hobtno${dateW}`;
              const refNameH = `hobtnh${dateW}`;

              //console.log(refNameO);

              if (type == 'o') {                
                this.$refs[refNameO].classList.add('ho-active');
                this.$refs[refNameH].classList.remove('ho-active');
              } 
              else {
                this.$refs[refNameH].classList.add('ho-active');
                this.$refs[refNameO].classList.remove('ho-active');
              }

            }
            else {
              this.error = "(030b) "+response.data.Text;
              setTimeout(() => {
                this.reloadPage();
              }, 600);
            }
          }
        })
        .catch((e) => shared.handleError.call(this, '031b', false, e));






    },

    jobExistCheck(jobId) {

      if (this.jobs.filter(e => e.jobId === jobId).length > 0) {
        return true
      }
      else return false;

    },

    jobDescr(jobId) {
      //console.log("jobDescr");
      //console.log(jobId);
      const job = this.jobs.find(job => job.jobId === jobId);
      //console.log(job);
      //console.log(job ? job.jobDescr : 'Job not found');
      return job ? job.jobDescr : '';
      
    },

    fitTimeCellHeight() {

      if (this.setHoursRange.to - this.setHoursRange.from >= 14) {
      //this.timeCellHeight = 26;
      this.timeCellHeight = Math.round(this.windowHeight / 38);
      }
      else if (this.setHoursRange.to - this.setHoursRange.from < 14 && this.setHoursRange.to - this.setHoursRange.from >= 10 ) {
        //this.timeCellHeight = 34;
        this.timeCellHeight = Math.round(this.windowHeight / 27);
      }
      else {
      //this.timeCellHeight = 42;
        this.timeCellHeight = Math.round(this.windowHeight / 21.6);
      }

      if(this.windowHeight < 600) {
        this.timeCellHeight = 24;
      }

    },

    // ruJobHeight() {
    //   if(this.windowHeight < 850) {
    //     return {height: '57px'}
    //   }
    //   else {
    //     return {height: '76px'}
    //   }

    // },




    testDown(e) {

            // console.log('testDown');
            // console.log(e.keyCode);

            if(e.keyCode === 17 || e.keyCode === 18 || e.keyCode === 91 || e.keyCode === 93 ) {
              this.testKey = true;
              this.eventsSettings.drag = false;
            }


            if(e.keyCode === 120 ) {
              this.showClock = !this.showClock;
            }


            if(e.shiftKey && e.key === "D" ) {
              console.log("Shift + D");

              let val = JSON.parse(localStorage.userSettings);
              let darkMode = val.filter(function(el) { return (el.settingsId == 8 ); })[0].value;

              darkMode = darkMode == '0' ? '1' : '0';

              val.forEach(item => {
                if (item.settingsId === '8') {
                  item.value = darkMode;
                }
              });

              console.log(darkMode);

              this.$store.commit('setUserSettings', val);
              localStorage.userSettings = JSON.stringify(val);

              this.settDarkMode = darkMode == '0' ? false : true;
              this.setTheme(this.settDarkMode);
            }


            if(e.shiftKey && e.key === "W" ) {
              console.log("Shift + W");

              let val = JSON.parse(localStorage.userSettings);
              let isWeekend = val.filter(function(el) { return (el.settingsId == 1 ); })[0].value;

              isWeekend = isWeekend == '0' ? '1' : '0';

              val.forEach(item => {
                if (item.settingsId === '1') {
                  item.value = isWeekend;
                }
              });


              this.$store.commit('setUserSettings', val);
              localStorage.userSettings = JSON.stringify(val);

              this.settShowWeekends = this.getSettingFromArr(this.$store.getters.userSettings, 1, true);
              this.componentKey += 1;


            }

    },

    testUp(e) {


            //  console.log('testUp');
            //  console.log(e.keyCode);

            if(e.keyCode === 17 || e.keyCode === 18 || e.keyCode === 91 || e.keyCode === 93 ) {
              this.testKey = false;
              this.eventsSettings.drag = true;
            }

    },

    windowResize() {
     this.windowWidth = window.innerWidth;
     this.windowHeight = window.innerHeight;
     this.rcContainerHeight = this.windowHeight - 450;
      if (this.windowHeight < 850) {
              this.rcContainerHeight = this.windowHeight - 400;
      }
    //  console.log('windowResize');

     this.fitTimeCellHeight();


    },

    onDragOver(e) {
        this.eventsSettings.drag = true;
        this.dragState = false;
        //e.preventDefault();

         console.log(e);

    },

    usersListGet() {
      let newArr = [];

      this.usersList.forEach(element => {
         newArr.push({Id: element.Id, Name: element.FirstName+' '+element.LastName, Status: element.Status});
      });

      return newArr;
    },


    timeChecker() {

      if (((parseInt(this.selectedEvent.startTime.hours,10) * 60) + parseInt(this.selectedEvent.startTime.minutes,10)) >= ((parseInt(this.selectedEvent.endTime.hours,10) * 60) + parseInt(this.selectedEvent.endTime.minutes,10))) {
          this.warningMessageCreationDialog = "The start time cannot be the same or later than the end time";
      }
      else {
        this.warningMessageCreationDialog = "";
      }

    },

    checkDisable(nojob, jobid, nojobuser, nojobdescr) {

      if (nojob && (nojobuser === 0 || nojobuser === null || nojobdescr == null || nojobdescr == '')) {
        return true;
      }
      if (!nojob && (jobid === 0 || jobid === null)) {
        return true;
      }

      return false;
    },



    onEventDragStart (e, draggable) {

      // console.log("------------onEventDragStart-------------");
      // console.log(e);
      // console.log(draggable);

      this.dragState = true;

      if (draggable.clientName === undefined) {
        draggable.clientName = draggable.client;
      }

      if (draggable.time === undefined) {
        draggable.duration = this.timeToMin(this.settDropDefTime);
      }
      else {
        draggable.duration = this.timeToMin(draggable.time);
      }

      if (draggable.id  != 'tmp2') {

        let idx = this.events.findIndex((x) => x.id === draggable.id);
        this.events[idx].noDel = true;

        draggable.id = 'tmp2';

      }

      // console.log(draggable.duration);

      // setTimeout(() => {
      //   this.eventsSettings.drag = true;
      // }, 200);

      //draggable.time = this.settDropDefTime;

      // console.log(draggable);

      // Passing the event's data to Vue Cal through the DataTransfer object.
      e.dataTransfer.effectAllowed = 'copy';
      e.dataTransfer.setData('event', JSON.stringify(draggable))
      e.dataTransfer.setData('cursor-grab-at', e.offsetY)
    },

    previous() {
      // console.log("------------previous:start-------------");
      try {
        // this.$refs.vuecal.previous();
        if (this.activeView === "day") {
          this.selectedDate = new Date(this.selectedDate.getTime()).addDays(-1);
        } else if (this.activeView === "week") {
          this.selectedDate = new Date(this.selectedDate.getTime()).addDays(-7);
          
        } else if (this.activeView === "month") {
          let dt = new Date(this.selectedDate.getTime());
          let daysInMonth = new Date(dt.getFullYear(), dt.getMonth(), 0).getDate();
          if (dt.getDate() > daysInMonth) {
            dt = new Date(dt.getFullYear(), dt.getMonth() - 1, daysInMonth);
          } else {
            dt.setMonth(dt.getMonth() - 1);
          }
          this.selectedDate = dt;
        } else if (this.activeView === "year") {
          let dt = new Date(this.selectedDate.getTime());
          let daysInMonth = new Date(dt.getFullYear() - 1, dt.getMonth() + 1, 0).getDate();
          if (dt.getDate() > daysInMonth) {
            dt = new Date(dt.getFullYear() - 1, dt.getMonth(), daysInMonth);
          } else {
            dt.setFullYear(dt.getFullYear() - 1);
          }
          this.selectedDate = dt;
        }
      } catch (error) {
        console.log("--------ERROR ------------");
        console.dir(error);
      }
      // console.log("------------previous:end-------------");
    },

    next() {
      // console.log("------------next:start-------------");
      try {
        // this.$refs.vuecal.next();
        if (this.activeView === "day") {
          this.selectedDate = new Date(this.selectedDate.getTime()).addDays(1);
        } else if (this.activeView === "week") {
          this.selectedDate = new Date(this.selectedDate.getTime()).addDays(7);
        } else if (this.activeView === "month") {
          let dt = new Date(this.selectedDate.getTime());
          let daysInMonth = new Date(dt.getFullYear(), dt.getMonth() + 2, 0).getDate();
          if (dt.getDate() > daysInMonth) {
            dt = new Date(dt.getFullYear(), dt.getMonth() + 1, daysInMonth);
          } else {
            dt.setMonth(dt.getMonth() + 1);
          }
          this.selectedDate = dt;
        } else if (this.activeView === "year") {
          let dt = new Date(this.selectedDate.getTime());
          let daysInMonth = new Date(dt.getFullYear() + 1, dt.getMonth() + 1, 0).getDate();
          if (dt.getDate() > daysInMonth) {
            dt = new Date(dt.getFullYear() + 1, dt.getMonth(), daysInMonth);
          } else {
            dt.setFullYear(dt.getFullYear() + 1);
          }
          this.selectedDate = dt;
        }
      } catch (error) {
        console.log("--------ERROR ------------");
        console.dir(error);
      }
      // console.log("------------next:end-------------");
    },

    sOnEventReady({ view, startDate, endDate }) {
      this.sActiveView = view;
      this.sDataFrom = startDate;
      this.sDataTo = endDate;

      this.sholidayList({
          dateFrom: this.sDataFrom.format("YYYY-MM-DD"),
          dateTo: this.sDataTo.format("YYYY-MM-DD"),
        });

      this.entiresDataSum();
    },

    sOnViewChange(event) {

      // console.log("--------sOnViewChange ------------");
      //   console.dir(event);

      this.sActiveView = event.view;
      if (event.view === "month") {
        this.sDataFrom = event.firstCellDate;
        this.sDataTo = event.lastCellDate;

        this.sholidayList({
          dateFrom: this.sDataFrom.format("YYYY-MM-DD"),
          dateTo: this.sDataTo.format("YYYY-MM-DD"),
        });
      } else {
        this.sDataFrom = event.startDate;
        this.sDataTo = event.endDate;
      }
      this.entiresDataSum();


    },

    sEventsCount: (events) => {

      return events && events.length === 1 ? events[0].time : "";

    },

    eventsCount: (events, cell) => {
      //  console.log('----%%%%events%%%%---');
      //  console.log( typeof cell !== "undefined" ? cell.formattedDate : "---");
      //  console.log(events);
       console.log(cell);
      return events && events.length === 1 ? events[0].time : "";

    },



    eventsCountDay(startDate, endDate){

      //let sum = 0;
      this.dataEventCountDay = [];
      let arr = this.getDaysArray(startDate, endDate);
      let arrObj = [];


      for(var i=0; i < arr.length; i++ ) {
        arrObj[i] = {
          date: arr[i].format("YYYY/MM/DD").toString(),
          sum: "00:00"
        };

        this.dataEventCountDay.push(arrObj[i]);
      }


      // console.log('----arrObj---');
      //  console.dir(arrObj);

      //  console.log('----this.dataEventCountDay---');
      //  console.dir(this.dataEventCountDay);


      // for(var j=0; j < arrObj.length; j++ ) {

      //   let dayArr = this.events.filter( function(el) {
      //     return new Date(el.start).format("YYYY/MM/DD").toString() == new Date(arrObj[j].date).format("YYYY/MM/DD").toString();
      //   });

      //   console.log('----%%dayArr%%---');
      //   console.dir(dayArr);

      //   var sum = a.reduce(function(a, b) { return a + b; }, 0);
      // }



      // console.log('----%%%%arr%%%%---');
      // console.dir(arr);
      // console.dir(arrObj);

      // event.forEach(element => {
      //     sum = sum + this.timeToMin(element.time);

      //   });

      // console.log('----%%%%events%%%%---');
      // console.dir(events);
      //this.dataEventCountDay.push(this.minToTime(sum));



    },

    eventsCountDaySum(arr) {

      //let dayArr =[];
      this.dataEventCountTotalWeek = 0;

      for(var j=0; j < arr.length; j++ ) {

        let tmpArr = [];

        let dayArr = this.events.filter( function(el) {
          return new Date(el.start).format("YYYY/MM/DD").toString() == arr[j].date;
        });

        // console.log('--%%dayArr%%--: '+j+' : '+arr[j].date);
        // console.dir(dayArr);

         for(var k=0; k < dayArr.length; k++ ) {

            // console.log('dayArr: '+k);
            // console.log(dayArr[k].time);

            tmpArr.push(this.timeToMin(dayArr[k].time));
         }


        // console.log('--%%dtmpArr%%--: '+arr[j].date);
        // console.dir(tmpArr);

        var sum = tmpArr.reduce(function(a, b) { return a + b; }, 0);

        this.dataEventCountDay[j].sum = this.minToTime(sum);
        this.dataEventCountTotalWeek = this.dataEventCountTotalWeek + sum;
      }

    },

    periodTimePicker(p, startEnd) {

      let ActStartTimeMin = (parseInt(this.selectedEvent.startTime.hours,10) * 60) + parseInt(this.selectedEvent.startTime.minutes,10)  + p;
      let ActEndTimeMin = (parseInt(this.selectedEvent.endTime.hours,10) * 60) + parseInt(this.selectedEvent.endTime.minutes,10)  + p;

      // console.log("---ActStartTimeMin---");
      // console.log(ActStartTimeMin);
      //  console.log("---ActEndTimeMin---");
      // console.log(ActEndTimeMin);

      if (startEnd === 'start') {
        if (ActStartTimeMin >= 360 && ActStartTimeMin <= 1200) {
          // this.selectedEvent.startTimeMinutes = this.selectedEvent.startTimeMinutes + p;
          let startTimeMin = this.minToTimeObj(ActStartTimeMin);
          this.selectedEvent.startTime = startTimeMin;

          //ActStartTimeMin = this.selectedEvent.startTimeMinutes;
        }

      }
      else {
        if (ActEndTimeMin >= 360 && ActEndTimeMin <= 1200) {
          //this.selectedEvent.endTimeMinutes = this.selectedEvent.endTimeMinutes + p;
          let endTimeMin = this.minToTimeObj(ActEndTimeMin);
          this.selectedEvent.endTime = endTimeMin;

          //ActEndTimeMin = this.selectedEvent.endTimeMinutes;
        }
      }

      if (((parseInt(this.selectedEvent.startTime.hours,10) * 60) + parseInt(this.selectedEvent.startTime.minutes,10)) >= ((parseInt(this.selectedEvent.endTime.hours,10) * 60) + parseInt(this.selectedEvent.endTime.minutes,10))) {
          this.warningMessageCreationDialog = "The start time cannot be the same or later than the end time";
      }
      else {
        this.warningMessageCreationDialog = "";
      }


    },

    selectedEventClear() {

      this.selectedEvent = {
        jobId: null,
        jobName: null,
        noJob: false,
        noJobId: null,
        noJobUserId: null,
        noJobDescr: null,
        startTime: {hours:'0',minutes:'0'},
        endTime: {hours:'0',minutes:'0'}
      };


    },

    onCellClick(date) {

      // console.log('******onCellClick - this.selectedEvent');
      // console.log(this.selectedEvent);

      if (typeof date !== "undefined" && date) {
        if (this.selectedDate !== date) {
          this.selectedDate = date;
        }
      }

      if(this.selectedEvent.id !== "tmp") {
          this.selectedEventClear();
      }

      this.closePropertyDialog();
      this.closeContextMenu();

      // console.log('******onCellClick - this.selectedEvent 2');
      // console.log(this.selectedEvent);

      // console.log('+++++++ EVENTS +++++++');
      // console.log(this.events);

    },


    onPaste(date) {
      // console.log('******Paste');
      // console.log(date);
      // console.log(this.copiedEvent);

      this.closeContextMenu();

      if (this.selectedEvent.id === undefined) {

        let newH = parseInt(date.getHours().toString(),10);
        let newM = parseInt(date.getMinutes().toString(),10);

        if (newM < 15) {
          newM = 0;
        }
        else if (newM >= 15 && newM < 45) {
          newM = 30;
        }
        else {
          newH = newH + 1;
          newM = 0;
        }

        if (newH >= 18) {
          newH = 18;
          newM = 0;
        }

        this.selectedEvent.startTime = this.minToTimeObj(newH * 60 + newM);

        let newEndM = newH * 60 + newM;

        let hh = this.timeToMin(this.copiedEvent.time);
        newEndM = newEndM + hh;


        if (newEndM > 1200) {
          newEndM = 1200;
        }
        this.selectedEvent.endTime = this.minToTimeObj(newEndM);

        // console.log(this.selectedEvent.startTime);
        // console.log(this.selectedEvent.endTime);

        this.selectedEvent.id = 0;
        this.selectedEvent.job = this.copiedEvent.job;
        this.selectedEvent.jobId = this.copiedEvent.jobId;
        this.selectedEvent.jobName = this.copiedEvent.jobName;
        this.selectedEvent.jobNo = this.copiedEvent.jobNo;
        this.selectedEvent.class = this.copiedEvent.class;
        this.selectedEvent.client = this.copiedEvent.client;
        this.selectedEvent.time = this.minToTime(hh);
        this.selectedEvent.noJob = this.copiedEvent.noJob;
        this.selectedEvent.noJobId = this.copiedEvent.noJobId;
        this.selectedEvent.noJobUserId = this.copiedEvent.noJobUserId;
        this.selectedEvent.noJobDescr = this.copiedEvent.noJobDescr;

        let startTime = new Date(date).format("YYYY-MM-DD").toString()+' '+this.noToTime(this.selectedEvent.startTime.hours, this.selectedEvent.startTime.minutes);
        let endTime = new Date(date).format("YYYY-MM-DD").toString()+' '+this.noToTime(this.selectedEvent.endTime.hours, this.selectedEvent.endTime.minutes);

        this.selectedEvent.start = startTime;
        this.selectedEvent.end = endTime;

        // console.log(this.selectedEvent);


        const entry = {
          start: this.selectedEvent.start,
          end: this.selectedEvent.end,
          jobId: this.selectedEvent.jobId,
          repeat: 0,
          repeatWeek: 0,
          noJobId: this.selectedEvent.noJobId,
          noJobUserId: this.selectedEvent.noJobUserId,
          noJobDescr: this.selectedEvent.noJobDescr
        };



        entiresService
          .add(entry)
          .then((response) => {
            // console.dir(response);
            if ((response.status === 200) & (response.data.Code === "OK")) {

              // console.log('response------');
              // console.log(response);

              const dataRes = JSON.parse(response.data.Result);

              // dataRes.length
              // console.log('dataRes------');
              // console.log(dataRes);

              this.selectedEvent.id = dataRes[0].id;
              this.events.push(this.selectedEvent);

              this.entiresDataSum();
              this.recentlyJobsRefresh();
              this.eventsCountDaySum(this.dataEventCountDay);
            }
            else {
              this.error = "(013) "+response.data.Text;
            }


          })
          .catch((e) => shared.handleError.call(this, '014', true, e));


      }


    },

    ContextMenuNew(date, hh) {
      this.onCellDbClick(date, hh, true);
    },


    onCellDbClick(date, hh, newMenu) {
      // console.log('******dbClick');
      // console.log(date);

      this.closeContextMenu();

      if(this.activeView ==='week' || this.activeView ==='day' || newMenu) {

        if (this.selectedEvent.id === undefined) {

          let newH = parseInt(date.getHours().toString(),10);
          let newM = parseInt(date.getMinutes().toString(),10);

          if (newM < 15) {
            newM = 0;
          }
          else if (newM >= 15 && newM < 45) {
            newM = 30;
          }
          else {
            newH = newH + 1;
            newM = 0;
          }

          if (newH >= 18) {
            newH = 18;
            newM = 0;
          }

          this.selectedEvent.startTime = this.minToTimeObj(newH * 60 + newM);
          let newEndM = newH * 60 + newM;

          if (hh === undefined) {
            newEndM = newEndM + this.timeToMin(this.settDropDefTime);
          }
          else {
            newEndM = newEndM + hh;
          }

          if (newEndM > 1200) {
            newEndM = 1200;
          }
          this.selectedEvent.endTime = this.minToTimeObj(newEndM);


          console.log("onCellDBClick - tmp");

          this.selectedEvent.id = "tmp";

          this.selectedEvent.jobId = null;
          this.selectedEvent.jobName = '';
          this.selectedEvent.jobNo = '';

          if (hh === undefined) {
            this.selectedEvent.time = this.settDropDefTime;
          }
          else {
            this.selectedEvent.time = this.minToTime(hh);
          }

          let startTime = new Date(date).format("YYYY-MM-DD").toString()+' '+this.noToTime(this.selectedEvent.startTime.hours, this.selectedEvent.startTime.minutes);
          let endTime = new Date(date).format("YYYY-MM-DD").toString()+' '+this.noToTime(this.selectedEvent.endTime.hours, this.selectedEvent.endTime.minutes);

          this.selectedEvent.start = startTime;
          this.selectedEvent.end = endTime;

          // console.log(this.selectedEvent);

          this.baseDialogTitle = 'New entry';
          this.showNewEventCreationDialog = true;
          this.showEventCreationDialog = true;
          setTimeout(() => {
           this.$refs.newEntry.focus();
          }, 50);
          

          this.onEventCreate(this.selectedEvent, () => {
              return true;
          });
        }

      }

    },

    onCellcontextMenu(event) {

      // console.log('******contextMenu');
      // console.log(event);
      // console.log(this.selectedEvent.id);
      // console.log(event.e.target.className);
      // console.log('x: '+event.x+'y: '+event.y);
      // console.log(event.date);

       this.closePropertyDialog();

      let rClientX = event.e.clientX +5 ;
      let rClientY = event.e.clientY - 20;

      this.modalContextTop = "" + rClientY + "px";

      if (event.e.clientX + 164 > this.windowWidth) {
        rClientX = rClientX - 164 - 10;
        this.modalContextLeft = "" + rClientX + "px";
        this.contextMenuLeftSide = true;
      } else {
        this.modalContextLeft = "" + rClientX + "px";
        this.contextMenuLeftSide = false;
      }

      this.selectedDate = event.date;

      //if(event.e.target.className == 'vuecal__flex vuecal__cell-content') {
      if(event.e.target.className == 'wrap_cell-date' || event.e.target.className == 'wrap_cell-date holi-date') {

        this.selectedEventClear();

        setTimeout(() => {
           this.showContextMenu = true;
        }, 100);
      }
      else {
        setTimeout(() => {
          if(!this.showEventCreationDialog){
            if(!this.selectedEvent.toDel) {
              this.showPropertyDialog = true;
            }
          }
        }, 200);
      }


    },

    goToDayView(date) {
      this.selectedDate = date;
      this.activeView = "day";
      this.$refs.vuecal.switchView(this.activeView, this.selectedDate);
    },

    onEventCreate(event, deleteEventFunction) {
      // console.log("---onEventCreate---");
      // console.log(event);
      // console.log(event.start);
      // console.log(event.end);
      // console.log(event.time);



      event.id = "tmp";
      event.jobId = Object.prototype.hasOwnProperty.call(event, 'jobId') ? event.jobId : null;
      event.jobName = Object.prototype.hasOwnProperty.call(event, 'jobName') ? event.jobName : '';
      event.noJob = Object.prototype.hasOwnProperty.call(event, 'noJob') ? event.noJob : false;
      event.noJobId = Object.prototype.hasOwnProperty.call(event, 'noJobId') ? event.noJobId : null;
      event.noJobUserId = Object.prototype.hasOwnProperty.call(event, 'noJobUserId') ? event.noJobUserId : null;
      event.noJobDescr = Object.prototype.hasOwnProperty.call(event, 'noJobDescr') ? event.noJobDescr : null;

      if (event.noJobUserId !== null && event.noJobUserId !== 0 ) {
        event.noJob = true;

        if (event.noJobDescr === null) {
          event.noJobDescr = event.jobName;
        }

      }


      if (event.time == undefined) {
        event.time = this.settDropDefTime;
      }

      this.selectedEvent = event;

      this.deleteEventFunction = deleteEventFunction;
      this.events.push(event);

      this.eventsCountDaySum(this.dataEventCountDay);

      // console.log("---onEventCreate - selectedEvent---");
      // console.log(this.selectedEvent);


      return event;
    },

    onEventCopy(event, e) {
      this.selectedEvent.toDel = true;

      // console.log("---onEventCopy---");
      // console.log(event);
      // console.log(e);

      if (event.id === this.copiedEvent.id) {
        this.copiedEvent = {id:0};
        this.copiedHintText = "Cleared";
      }
      else {
        this.copiedEvent = event;
        this.copiedHintText = "Copied";
      }

      let rClientX = e.pageX - 18;
      let rClientY = e.pageY - 36;
      this.modalTopCopied = "" + rClientY + "px";
      this.modalLeftCopied = "" + rClientX + "px";

      this.showCopied = true;

      setTimeout(() => {
           this.showCopied = false;
      },1600);


    },

    onEventDragCreate() {

      // console.log("onEventDragCreate - selectedEvent");
      // console.log(this.selectedEvent);

      this.selectedEvent.startTime = {
          hours: this.selectedEvent.start.getHours().toString(),
          minutes: this.selectedEvent.start.getMinutes().toString()
      };

      this.selectedEvent.endTime = {
         hours: this.selectedEvent.end.getHours().toString(),
         minutes: this.selectedEvent.end.getMinutes().toString()
      };

      let timeMin = this.selectedEvent.endTimeMinutes -  this.selectedEvent.startTimeMinutes;
      this.selectedEvent.time = this.minToTime(timeMin);

      // console.log("onEventDragCreate - selectedEvent 2");
      // console.log(this.selectedEvent);



      this.baseDialogTitle = 'New entry';
      this.showNewEventCreationDialog = true;
      this.showEventCreationDialog = true;
      setTimeout(() => {
           this.$refs.newEntry.focus();
          }, 50);


      // if (this.selectedEvent.jobId === null) {
      //   this.warningMessageCreationDialog = 'Honey, first you should select Job number';
      // }
      // else {
      //   this.warningMessageCreationDialog = '';
      // }


    },


    cancelEventCreation() {
      // console.log("cancelEventCreation - selectedEvent");
      // console.log(this.selectedEvent);

      this.showEventCreationDialog = false;
      this.selectedRepeatDay = 0;
      this.selectedRepeatWeek = 0;

      this.selectedEventClear();



      if(this.showNewEventCreationDialog) {

        this.deleteEventFunction();
      }
      this.showNewEventCreationDialog = false;

      let idx = this.events.findIndex((x) => x.id == "tmp");

      if (idx >= 0 ) {
        this.events.splice(idx,1);
      }

    },

    closeCreationDialog() {
      // console.log("closeCreationDialog - selectedEvent");
      // console.log( this.selectedEvent);

      if (this.selectedEvent.noJob) {
            this.selectedEvent.jobId = 0;
      }


      if (this.selectedEvent.jobId === null && (this.selectedEvent.noJobUserId === 0 || this.selectedEvent.noJobUserId === null) ) {
        this.warningMessageCreationDialog = 'Honey, first you should select Job number';
      }
      else {
        this.warningMessageCreationDialog = '';

        let startTime = this.noToTime(this.selectedEvent.startTime.hours, this.selectedEvent.startTime.minutes);
        let endTime = this.noToTime(this.selectedEvent.endTime.hours, this.selectedEvent.endTime.minutes);

        let startTimeS = startTime.substring(0,5);
        let endTimeS = endTime.substring(0,5);
        let timeS = this.minToTime(parseInt(this.timeToMin(endTimeS),10) - parseInt(this.timeToMin(startTimeS),10));

         let timeSett = {
           startTime: {hours: this.selectedEvent.startTime.hours, minutes: this.selectedEvent.startTime.minutes},
           endTime: {hours: this.selectedEvent.endTime.hours, minutes: this.selectedEvent.endTime.minutes},
           startTimeMinutes: this.timeToMin(startTimeS),
           endTimeMinutes: this.timeToMin(endTimeS),
           time: timeS
         };

        if (this.timeToMin(endTime) < this.timeToMin(startTime)) {
          this.warningMessageCreationDialog = 'The start time cannot be the same or later than the end time';

        }
        else {


          startTime = new Date(this.selectedDate).format("YYYY/MM/DD").toString()+' '+startTime;
          endTime = new Date(this.selectedDate).format("YYYY/MM/DD").toString()+' '+endTime;

          // let startTime2 = '2022/04/15 09:30:00';

            // console.log('!!!!-d1');
            // console.log(startTime);
            // console.log(endTime);

          let startTimeD = new Date(startTime);
          let endTimeD = new Date(endTime);

          // let startTime2D = new Date(startTime2);

            // console.log('!!!!-d2');
            // console.log(startTimeD);
            // console.log(endTimeD);
            // console.log(startTime2D);


          if (this.selectedEvent.id === "tmp") {
            const entry = {
              start: startTimeD.format("YYYY-MM-DD HH:mm"),
              end: endTimeD.format("YYYY-MM-DD HH:mm"),
              jobId: this.selectedEvent.jobId,
              repeat: this.selectedRepeatDay,
              repeatWeek: this.selectedRepeatWeek,
              noJobId: this.selectedEvent.noJobId,
              noJobUserId: this.selectedEvent.noJobUserId,
              noJobDescr: this.selectedEvent.noJobDescr
            };

            //console.log('!!!!entry');

            //console.log(entry);

            entiresService
              .add(entry)
              .then((response) => {

                if ((response.status === 200) & (response.data.Code === "OK")) {
                  // console.log('response------');
                  // console.log(response);

                  const dataRes = JSON.parse(response.data.Result);

                  // dataRes.length
                  // console.log('dataRes.length------');
                  // console.log(dataRes.length);

                  let idx = this.events.findIndex((x) => x.id == "tmp");
                  // console.log('idx------idx');
                  // console.log(idx);


                  for (var j = 0; j < dataRes.length; j++) {
                    // console.log(dataRes[j].id);
                    // console.log('----1aa----, index:'+ j);


                    if (j==0) {

                      entiresService
                      .get(dataRes[j].id)
                      .then((response2) => {

                        //console.dir(response2);

                        if (response2.status === 200) {

                          // console.log(response2);
                          // console.log('----1bb----, index:'+ j);


                            //let idx = this.events.findIndex((x) => x.id == "tmp");
                            this.events[idx].id = response2.data.id;
                            this.events[idx].start = new Date(response2.data.start).format("MM/DD/YYYY HH:mm:00");
                            this.events[idx].end = new Date(response2.data.end).format("MM/DD/YYYY HH:mm:00");
                            this.events[idx].class = response2.data.class;
                            this.events[idx].client = response2.data.client;
                            this.events[idx].job = response2.data.job;
                            this.events[idx].jobId = response2.data.jobId;
                            this.events[idx].jobNo = response2.data.jobNo;
                            this.events[idx].jobName = response2.data.jobName;
                            this.events[idx].noJobId = response2.data.noJobId;
                            this.events[idx].noJobUserId = response2.data.noJobUserId;
                            this.events[idx].time = timeSett.time;

                          //this.entiresDataSum();

                          // console.log('events');
                          // console.log(this.events);

                        }
                      })
                      .catch((e) => shared.handleError.call(this, '015', false, e));

                    }
                    else {

                      entiresService
                      .get(dataRes[j].id)
                      .then((response2) => {
                        //console.dir(response2);

                        if (response2.status === 200) {
                          // console.log(response2);
                          // console.log('----1cc----, index:'+ j);



                            const tmpEvent = {
                              id: response2.data.id,
                              start: new Date(response2.data.start).format("MM/DD/YYYY HH:mm:00"),
                              end: new Date(response2.data.end).format("MM/DD/YYYY HH:mm:00"),
                              class: response2.data.class,
                              client: response2.data.client,
                              job: response2.data.job,
                              jobId: response2.data.jobId,
                              jobNo: response2.data.jobNo,
                              jobName: response2.data.jobName,
                              noJobId: response2.data.noJobId,
                              noJobUserId: response2.data.noJobUserId,
                              time: timeSett.time,
                              startTime: timeSett.startTime,
                              startTimeMinutes: timeSett.startTimeMinutes,
                              endTime: timeSett.endTime,
                              endTimeMinutes: timeSett.endTimeMinutes


                            };

                            this.events.push(tmpEvent);

                          //this.entiresDataSum();

                          // console.log('events');
                          // console.log(this.events);

                        }
                      })
                      .catch((e) => shared.handleError.call(this, '016', false, e));

                    }
                  }

                  // console.log("--dataEventCountDay--");
                  // console.log(this.dataEventCountDay);

                  this.entiresDataSum();
                  this.recentlyJobsRefresh();
                  setTimeout(()=>{
                    this.eventsCountDaySum(this.dataEventCountDay);
                  },500);

                }
                else {

                  this.error = "(017) "+response.data.Text;

                }
              })
              .catch((e) => shared.handleError.call(this, '018', false, e));
          }
          else {

            this.entiresEdit(this.selectedEvent, true);

          }
          this.eventsCountDaySum(this.dataEventCountDay);
          //console.log("showNewEventCreationDialog-closeCreationDialog");
          this.showNewEventCreationDialog = false;
          this.showEventCreationDialog = false;
          this.selectedRepeatDay = 0;
          this.selectedRepeatWeek = 0;
          this.selectedEventClear();
        }
      }
    },

    closePropertyDialog() {
      // console.log("closePropertyDialog - selectedEvent");
      // console.log( this.selectedEvent);
      this.showPropertyDialog = false;
    },

    closeContextMenu() {
      this.showContextMenu = false;
    },

    recentlyJobsRefresh(){

      if (this.recentlyJobsShow) {

        this.recentJobs = [];

        jobsService
        .recentJobs()
        .then((response) => {
          if (response.status === 200) {
            this.recentJobs = response.data;
                  // console.log("recentJobs");
                  // console.log( this.recentJobs);

            // if (this.windowHeight < 850) {
            // this.recentJobs.pop();
            // }
          }
        })
        .catch((e) => shared.handleError.call(this, '019', false, e));


      }
      else {
        this.recentJobs = [];

        jobsService
        .nonClientJobs()
        .then((response) => {
          if (response.status === 200) {
            this.recentJobs = response.data;   
            //  console.log("recentJobs");
            //  console.log( this.recentJobs);       
          }
        })
        .catch((e) => shared.handleError.call(this, '019a', false, e));
      }

      
    },

    noJobCountRefresh() {

      userService
      .userNoJobCount()
      .then((response) => {

        if (response.status === 200) {
          this.userNoJobCount = response.data;
        }

      })
      .catch((e) => shared.handleError.call(this, '020', false, e));

    },

    onEventClick(event, e) {

      // console.log("onEventClick - e");
      // console.log(e);
      // console.log("onEventClick - event");
      // console.log(event);

      // console.log("onEventClick - selectedEvent");
      // console.log(this.selectedEvent);

      event.noJob = false;

      if(event.jobId === 0) {
        event.noJob = true;
      }

      this.showPropertyDialog = false;
      this.closeContextMenu();

      let rClientX = e.clientX +5 ;
      let rClientY = e.clientY - 20;
      //console.log(rClientX);
      this.modalTop = "" + rClientY + "px";

      if (e.clientX + 300 > this.windowWidth) {
        rClientX = rClientX - 300 - 10;
        this.modalLeft = "" + rClientX + "px";
        this.propertyDialogLeftSide = true;
      } else {
        this.modalLeft = "" + rClientX + "px";
        this.propertyDialogLeftSide = false;
      }

      this.selectedEvent = event;

      if(!this.selectedEvent.noJob) {
        this.selectedEvent.noJobId = null;
        this.selectedEvent.noJobUserId = null;
        this.selectedEvent.noJobDescr = null;
      }

      // setTimeout(() => {
      //   if(!this.showEventCreationDialog){
      //     if(!this.selectedEvent.toDel) {
      //       this.showPropertyDialog = true;
      //     }
      //   }
      // }, 200);

      // console.log("----------");
      // console.dir(this.selectedEvent);

      e.stopPropagation();

      // console.log("onEventClick - selectedEvent 2");
      // console.log(this.selectedEvent);

    },

    onEventDurationChange(event) {

      // console.log("onEventDurationChange - event");
      // console.log(event);

      // console.log("onEventDurationChange - selectedEvent");
      // console.log(this.selectedEvent);

      if (event.event.jobId === 0 )
        {
          event.event.noJobId = event.originalEvent.noJobId;
          event.event.noJobUserId = event.originalEvent.noJobUserId;
          event.event.noJobDescr = event.originalEvent.noJobDescr;
          event.event.noJob = true;
        }

      this.entiresEdit(event.event, false);
    },

    onEventDrop(event) {

      // console.log("onEventDrop - selectedEvent");
      // console.log(this.selectedEvent);
      // console.log("onEventDrop - events");
      // console.log(this.events);


      if (this.selectedEvent.id === "tmp") {


         if (event.originalEvent.id === "tmp2") {

            //console.log("onEventDrop - jest: tmp2");
            this.selectedDate = event.event.start;
            this.selectedEvent.jobId = event.originalEvent.jobId;
            this.selectedEvent.toDel = false;

            if (this.selectedEvent.time == undefined) {
              this.selectedEvent.time = this.settDropDefTime;
            }
         }

        this.selectedEvent.startTime = {
          hours: this.selectedEvent.start.getHours().toString(),
          minutes: this.selectedEvent.start.getMinutes().toString()
        };

        this.selectedEvent.endTime = {
          hours: this.selectedEvent.end.getHours().toString(),
          minutes: this.selectedEvent.end.getMinutes().toString()
        };

        let startTime = this.noToTime(this.selectedEvent.startTime.hours, this.selectedEvent.startTime.minutes);
        let endTime = this.noToTime(this.selectedEvent.endTime.hours, this.selectedEvent.endTime.minutes);
        startTime = new Date(this.selectedDate).format("YYYY/MM/DD").toString()+' '+startTime;
        endTime = new Date(this.selectedDate).format("YYYY/MM/DD").toString()+' '+endTime;
        let startTimeD = new Date(startTime);
        let endTimeD = new Date(endTime);

        const entry = {
          start: startTimeD.format("YYYY-MM-DD HH:mm"),
          end: endTimeD.format("YYYY-MM-DD HH:mm"),
          jobId: this.selectedEvent.jobId,
          repeat: 0,
          repeatWeek: 0,
          noJobId: this.selectedEvent.noJobId,
          noJobUserId: this.selectedEvent.noJobUserId,
          noJobDescr: this.selectedEvent.noJobDescr
        };

        entiresService
          .add(entry)
          .then((response) => {
            //console.dir(response);
            if ((response.status === 200) & (response.data.Code === "OK")) {
              //console.log('response------entiresService Add');
              //console.log(response);


                const dataRes = JSON.parse(response.data.Result);

                // console.log('-----dataRes-------');
                //       console.log(dataRes);

                entiresService
                  .get(dataRes[0].id)
                  .then((response2) => {
                    //console.dir(response2);


                    if (response2.status === 200) {
                    // console.log('response2------entiresService Get');
                    // console.log(response2);

                      let idx = this.events.findIndex((x) => x.id == "tmp");

                      if(idx >= 0) {

                        this.events[idx].id = response2.data.id;
                        this.events[idx].start = new Date(response2.data.start).format("MM/DD/YYYY HH:mm:00");
                        this.events[idx].end = new Date(response2.data.end).format("MM/DD/YYYY HH:mm:00");
                        this.events[idx].class = response2.data.class;
                        this.events[idx].client = response2.data.client;
                        this.events[idx].job = response2.data.job;
                        this.events[idx].jobId = response2.data.jobId;
                        this.events[idx].jobNo = response2.data.jobNo;
                        this.events[idx].jobName = response2.data.jobName;
                        this.events[idx].focused = false;


                        this.entiresDataSum();
                        this.recentlyJobsRefresh();
                        this.eventsCountDaySum(this.dataEventCountDay);

                        // to jest po to aby nie blokował się ctrl przy kopiowaniu
                        this.testKey = false;
                        this.eventsSettings.drag = true;


                      }



                    }
                  })
                  .catch((e) => shared.handleError.call(this, '021', false, e));




            }
            else {
              this.error = "(022) "+response.data.Text;
              setTimeout(() => {
                this.reloadPage();
              }, 600);
              
            }
          })
          .catch((e) => shared.handleError.call(this, '023', false, e));

      }
      else {

        if (event.event.jobId === 0 )
        {
          event.event.noJobId = event.originalEvent.noJobId;
          event.event.noJobUserId = event.originalEvent.noJobUserId;
          event.event.noJobDescr = event.originalEvent.noJobDescr;
          event.event.noJob = true;

        }


        // console.log('**entiresEdit**');
        // console.log(event.event);

        this.entiresEdit(event.event, false);

      }

    },

    onEventDelete(event, manual) {

      // console.log("onEventDelete - selectedEvent");
      // console.log(this.selectedEvent);
      // console.log(event);
      // console.log(manual);

      if (!manual) {
        manual = false;
      }

      this.selectedEvent.toDel = true;
      this.closePropertyDialog();
      this.closeContextMenu();

      if (event.id === "tmp") {
        this.events = this.events.filter(function (item) {
          return item.id !== event.id;
        });
      } else {

        if(event.noDel === true && manual == false) {
          this.selectedEvent.toDel = false;
          event.noDel = false;
        }
        else {
          this.entiresDelete(event);
        }


      }
    },

    onEventDblclick(event) {
      // console.log("onEventDblclick");
      // console.log(event);

      // console.log("onEventDblclick - selectedEvent");
      // console.log(this.selectedEvent);

      this.closePropertyDialog();
      this.closeContextMenu();
      this.selectedEvent = event;
      this.baseDialogTitle = this.selectedEvent.jobNo;

      // this.selectedEvent.startTime = [
      //   this.selectedEvent.start.getHours().toString(),
      //   this.selectedEvent.start.getMinutes().toString()
      // ];

      // this.selectedEvent.endTime = [
      //     this.selectedEvent.end.getHours().toString(),
      //     this.selectedEvent.end.getMinutes().toString()
      // ];

      this.selectedEvent.startTime = {
          hours: this.selectedEvent.start.getHours().toString(),
          minutes: this.selectedEvent.start.getMinutes().toString()
      };

      this.selectedEvent.endTime = {
          hours: this.selectedEvent.end.getHours().toString(),
          minutes: this.selectedEvent.end.getMinutes().toString()
      };

      //console.log("showNewEventCreationDialog-onEventDblclick");
      this.showNewEventCreationDialog = false;
      this.showEventCreationDialog = true;

      //console.log("onEventDblclick - selectedEvent 2");
      //console.log(this.selectedEvent);

      if (this.selectedEvent.jobId === null) {
        this.warningMessageCreationDialog = 'Honey, first you should select Job number';
      }
      else {
        this.warningMessageCreationDialog = '';
      }


      // Prevent navigating to narrower view (default vue-cal behavior).
      //e.stopPropagation();
    },
    onViewChange(event) {

      // console.log('---event view change----');
      // console.log(event)

      this.eventsCountDay(event.startDate, event.endDate);


      this.closePropertyDialog();
      this.closeContextMenu();
      this.noJobCountRefresh();
      //this.dataEventCountDay = [];

      this.activeView = event.view;
      if (event.view === "year" || event.view === "month") {
        this.dblclickToNavigate = true;
      } else {
        this.dblclickToNavigate = false;
      }

      if (event.view === "month") {
        this.entiresList({
          view: event.view,
          startDate: event.firstCellDate.format("YYYY-MM-DD HH:mm"),
          endDate: event.lastCellDate.format("YYYY-MM-DD HH:mm"),
        });

        this.holidayList({
          dateFrom: event.firstCellDate.format("YYYY-MM-DD"),
          dateTo: event.lastCellDate.format("YYYY-MM-DD"),
        });

      } else {
        this.entiresList({
          view: event.view,
          startDate: event.startDate.format("YYYY-MM-DD HH:mm"),
          endDate: event.endDate.format("YYYY-MM-DD HH:mm"),
        });

        this.holidayList({
          dateFrom: event.startDate.format("YYYY-MM-DD"),
          dateTo: event.endDate.format("YYYY-MM-DD"),
        });
      }


      if(event.view === 'week') {

        this.hoStatus({
          startDate: event.startDate.format("YYYY-MM-DD"),
          endDate: event.endDate.format("YYYY-MM-DD"),
        });
      }

    },

    onEventReady({ view, startDate, endDate }) {

      // console.log('onEventReady');
      // console.log(startDate);
      // console.log(endDate);

      this.eventsCountDay(startDate, endDate);

      this.entiresList({
        view: view,
        startDate: startDate.format("YYYY-MM-DD HH:mm"),
        endDate: endDate.format("YYYY-MM-DD HH:mm"),
      });

      this.holidayList({
          dateFrom: startDate.format("YYYY-MM-DD"),
          dateTo: endDate.format("YYYY-MM-DD"),
      });

      if(view === 'week') {

        this.hoStatus({
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        });
      }

    },

    entiresDataSum() {

      entiresService
        .dateSum({
          view: this.sActiveView,
          startDate: this.sDataFrom.format("YYYY-MM-DD HH:mm"),
          endDate: this.sDataTo.format("YYYY-MM-DD HH:mm"),
        })
        .then((response) => {
          if (response.status == "200") {
            this.sEventsDataSum = response.data;
            //console.log(this.sEventsDataSum);
          }
        })
        .catch((e) => shared.handleError.call(this, '024', false, e));
    },

    entiresList(data) {
        // console.log('entiresList');
        // console.log(data);
      entiresService
        .list(data)
        .then((response) => {
          if (response.status == "200") {
            this.events = response.data;
              //console.log('entiresList');
              //console.log(this.events);

            this.eventsCountDaySum(this.dataEventCountDay);

          }
        })
        .catch((e) => shared.handleError.call(this, '025', false, e));
    },

    hoStatus(data) {
      //console.log('hoList-data');
      //console.log(data);

      entiresService
        .hoList(data)
        .then((response) => {
          if (response.status == "200") {
            this.hoList = response.data;
              //console.log('hoList');
              //console.log(this.hoList);
          }
        })
        .catch((e) => shared.handleError.call(this, '0251', false, e));
    },


    checkHoDateAndType(date, type) {
        return this.hoList.some(element => element.date === date && element.type === type);
    },


    holidayList(data) {
      settingsService
      .holiday(data)
      .then((response) => {
          if (response.status == "200") {
            this.holiday = response.data;
            // console.log('holiday');
            // console.log(this.holiday);
          }
        })
        .catch((e) => shared.handleError.call(this, '026', false, e));
    },

    sholidayList(data) {
      settingsService
      .holiday(data)
      .then((response) => {
          if (response.status == "200") {
            this.sholiday = response.data;
            // console.log('sholiday');
            // console.log(this.sholiday);
          }
        })
        .catch((e) => shared.handleError.call(this, '027', false, e));

    },

    holiTest(date) {
      let arr = [];
       this.holiday.forEach(element => {
         arr.push(element.Date);
      });

      return arr.includes(date);
    },

    sholiTest(date) {
      let arr = [];
       this.sholiday.forEach(element => {
         arr.push(element.Date);
      });

      return arr.includes(date);
    },


    entiresDelete(event) {

      //console.log("entiresDelete");
      //console.dir(event);

      entiresService
        .delete(event.id)
        .then((response) => {
          if (response.status == "200") {
            this.events = this.events.filter(function (item) {
              return item.id !== event.id;
            });

            this.entiresDataSum();
            this.eventsCountDaySum(this.dataEventCountDay);
          }
        })
        .catch((e) => shared.handleError.call(this, '028', false, e));
    },

    entiresEdit(event, timeFromForm) {
      // console.log("entiresEdit");
      // console.dir(event);

      let entry = {};

      if (!event.noJob) {
        event.noJobId = null;
        event.noJobDescr = null;
        event.noJobUserId = null;
      }
      else {
        event.jobId = 0;
      }


      if (timeFromForm) {

        let startTime = this.noToTime(event.startTime.hours, event.startTime.minutes);
        let endTime = this.noToTime(event.endTime.hours, event.endTime.minutes);
        startTime = event.start.format("YYYY/MM/DD").toString()+' '+startTime;
        endTime = event.end.format("YYYY/MM/DD").toString()+' '+endTime;
        let startTimeD = new Date(startTime);
        let endTimeD = new Date(endTime);

        entry = {
          id: event.id,
          start: startTimeD.format("YYYY-MM-DD HH:mm"),
          end: endTimeD.format("YYYY-MM-DD HH:mm"),
          jobId: event.jobId,
          noJobId: event.noJobId,
          noJobUserId: event.noJobUserId,
          noJobDescr: event.noJobDescr,
          noJobChangeAll: this.noJobChangeAll ? 1 : 0
        };

      }
      else {
        entry = {
          id: event.id,
          start: event.start.format("YYYY-MM-DD HH:mm"),
          end: event.end.format("YYYY-MM-DD HH:mm"),
          jobId: event.jobId,
          noJobId: event.noJobId,
          noJobUserId: event.noJobUserId,
          noJobDescr: event.noJobDescr,
          noJobChangeAll: this.noJobChangeAll ? 1 : 0
        };


      }

      entiresService
        .edit(entry)
        .then((response) => {
          //console.dir(response);
          if (response.status === 200) {
            if (response.data.Code === "OK") {


              setTimeout(() => {



              entiresService
                .get(event.id)
                .then((response2) => {
                  //console.dir(response2);

                  //console.log('response2:');
                  //console.log(response2);

                  //console.log('-----przy 029 first-------');
                  //console.log('this.events:');
                  //console.log(this.events);

                  if (response2.status === 200) {
                    //console.log(response2);


                    console.log('event.id:');
                    console.log(event.id);


                    let idx = this.events.findIndex((x) => x.id == event.id);

                    console.log('idx:');
                    console.log(idx);

                    if (idx >= 0) {


                        this.events[idx].start = new Date(response2.data.start).format("MM/DD/YYYY HH:mm:00");
                        this.events[idx].end = new Date(response2.data.end).format("MM/DD/YYYY HH:mm:00");
                        this.events[idx].class = response2.data.class;
                        this.events[idx].client = response2.data.client;
                        this.events[idx].job = response2.data.job;
                        this.events[idx].jobId = response2.data.jobId;
                        this.events[idx].jobNo = response2.data.jobNo;
                        this.events[idx].jobName = response2.data.jobName;
                        this.events[idx].time = response2.data.time;
                        this.events[idx].noJobId = response2.data.noJobId;
                        this.events[idx].noJobUserId = response2.data.noJobUserId;
                        this.events[idx].noJobDescr = response2.data.noJobDescr;

                        //console.log('-----przy 029 ok-------');
                        //console.log('this.events:');
                        //console.log(this.events);


                        this.entiresDataSum();
                        this.eventsCountDaySum(this.dataEventCountDay);

                    }

                  }
                })
                .catch((e) => shared.handleError.call(this, '029', false, e));


              this.entiresDataSum();


              if (this.noJobChangeAll) {

                //var curr = this.selectedDate; // get current date

                var startOfWeek = moment(this.selectedDate).startOf('isoweek').toDate();
                var endOfWeek   = moment(this.selectedDate).endOf('isoweek').toDate();

                //  console.log("firstday");
                //  console.log(startOfWeek.format("YYYY-MM-DD HH:mm"));
                // console.log("lastday");
                //  console.log(endOfWeek.format("YYYY-MM-DD HH:mm"));

                this.entiresList({
                  view: this.activeView,
                  startDate: startOfWeek.format("YYYY-MM-DD HH:mm"),
                  endDate: endOfWeek.format("YYYY-MM-DD HH:mm"),
                });


              }

              this.noJobChangeAll = false;

              }, 69);

            }
            else {
              this.error = "(030) "+response.data.Text;
              setTimeout(() => {
                this.reloadPage();
              }, 600);
            }
          }
        })
        .catch((e) => shared.handleError.call(this, '031', false, e));


    },

    eventCountClass(min) {
      switch(true) {
        case (min > 0 && min <=120 ):
          return 'low';
        case (min > 120 && min <= 300):
          return 'mid';
        case (min > 300):
          return 'high';
        default:
          return '';
      }

    },

    handleError() {
      this.error = null;
    },
  },

};
</script>

<style>


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.3s ease-in;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: scale(1);
}



.wrap {
  display: flex;
  margin: 16px;

}

.wrap.wrap-cal {
  align-items: flex-start;
}


.mx-2 {
  width: 240px;
  margin-right: 16px;
  position: relative;
  display: block;
}


.hide-cal {
  -webkit-animation: slide-out-left 0.6s ease-out both;
	animation: slide-out-left 0.6s ease-out both;
}

.show-cal {
  -webkit-animation: slide-in-left 0.6s ease-out both;
	animation: slide-in-left 0.6s ease-out both;
}

@-webkit-keyframes slide-out-left {
  0% {
    margin-left: 0px;
    opacity: 1;
  }
  100% {
    margin-left: -257px;
    opacity: 0.5;
  }
}
@keyframes slide-out-left {
  0% {
    margin-left: 0px;
    opacity: 1;
  }
  100% {
    margin-left: -257px;
    opacity: 0.5;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    margin-left: -257px;
    opacity: 0.5;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    margin-left: -257px;
    opacity: 0.5;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}


.mx-10 {
  width: auto;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  position: relative;
}


.hide-menu {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.show-menu {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);

  }
  100% {
    -webkit-transform: translateY(294px);
    transform: translateY(294px);

  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);

  }
  100% {
    -webkit-transform: translateY(294px);
    transform: translateY(294px);

  }
}

@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(294px);
    transform: translateY(294px);

  }
  100% {
    -webkit-transform: translateY(-234px);
    transform: translateY(-234px);

  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(294px);
    transform: translateY(294px);

  }
  100% {
    -webkit-transform: translateY(-234px);
    transform: translateY(-234px);

  }
}


/* checkboxy */

.rad-label {
  display: inline-block;
  align-items: center;

  border-radius: 100px;
  padding: 6px 0 0 6px;

  cursor: pointer;
  transition: .3s;
  position: relative;
}

.rad-label.left-label {
  padding: 6px 6px 0 0;
}

.rad-label:hover,
.rad-label:focus-within {
  background: hsla(0, 0%, 80%, .14);
}

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 15px;
  height: 15px;
  background: linear-gradient(to right bottom,  #c25a10, #ffdb6e);
  position: relative;
}



.rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 80%);
  transform: scale(1.1);
  transition: .3s;
}

.rad-input:checked+.rad-design::before {
  transform: scale(0);
}

.rad-text {
  color: hsl(0, 0%, 60%);
  margin-left: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  transition: .3s;
}

.rad-input:checked~.rad-text {
  color: hsl(0, 0%, 40%);
}

.rad-input:checked~.rad-text {
  color: hsl(0, 0%, 40%);
}


/* checkboxy */

.no-job-wrapper .rad-label {
  display: inline-block;
  align-items: center;

  border-radius: 100px;
  padding: 6px 0 0 6px;

  cursor: pointer;
  transition: .3s;
}

.no-job-wrapper .rad-label.left-label {
  padding: 6px 6px 0 0;
}

.no-job-wrapper .rad-label:hover,
.no-job-wrapper .rad-label:focus-within {
  background: hsla(0, 0%, 80%, .14);
}

.no-job-wrapper .rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.no-job-wrapper .rad-design {
  width: 15px;
  height: 15px;
  /* border-radius: 6px; */

  background: linear-gradient(to right bottom,  #c25a10, #ffdb6e);
  position: relative;
}



.no-job-wrapper .rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 80%);
  transform: scale(1.1);
  transition: .3s;
}

.no-job-wrapper .rad-input:checked+.rad-design::before {
  transform: scale(0);
}

.no-job-wrapper .rad-text {
  color: hsl(0, 0%, 60%);
  margin-left: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  transition: .3s;
}

.no-job-wrapper .rad-input:checked~.rad-text {
  color: hsl(0, 0%, 40%);
}



/* buttony w header */


.nav-btn {
  border: 1px solid #bcbcbc;
  padding: 8px 12px 10px;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: -1px;
  background-color: #e1e1e1;
  font-family: 'Lato-Bold';
  min-width: 50px;
  cursor: pointer;
}
.nav-btn:hover {

  background-color: #d1d1d1;
}


.nav-btn.view-active {
  background-color: #aaa;
  color: white;
}

.nav-header .nav-btn.nav-cmp  {
  padding: 7px 14px 7px;
  margin-right: 16px;
}

.nav-header .nav-btn.nav-cmp i {
  transform: rotate(45deg);
  font-size: 20px;
}

/*
.nav-btn {

  background:
    linear-gradient(
      to right,
      rgba(225, 225, 225, 1),
      rgba(225, 225, 225, 1)
    ),
    linear-gradient(
      to right,
      rgba(189, 189, 189, 1),
      rgba(209, 209, 209, 1),
      rgba(242, 242, 242, 1)

  );
  background-size: 100% 100%, 0 100%;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
}

.nav-btn:hover {
  background-size: 0 100%, 100% 100%;
} */


.ring-btn {
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
}

.ring-btn i {
  color: red;
  font-size: 18px;
  animation: ringmove 2s infinite;
  animation-timing-function: ease;
  transform: rotate(45deg);
}

.ring-btn .ring-info {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 7px;
  color: white;
  background-color: #9c9c9c;
  font-size: 8px;
  padding: 3px;
  min-width: 9px;
}


@keyframes ringmove {
  0% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(45deg);
  }

}


/* lewy kalendarz */

.vuecal--date-picker.demo {
  width: 240px;
  height: 250px;
}

.vuecal--date-picker.demo .vuecal__weekdays-headings {
  padding-right: 0;
}

.vuecal--date-picker.demo .vuecal__weekdays-headings .vuecal__heading {
  opacity: .5;
    font-family: 'Anton-Regular';
}




/* Górne menu - flex */




.vuecal.main-cal .vuecal__header .vuecal__weekdays-headings,
.vuecal.main-cal .vuecal__header .vuecal__title {
  padding: 10px 0 10px 3em;
  font-family: 'Lato-Bold';
}

.vuecal.main-cal .vuecal__header .vuecal__weekdays-headings .weekday-no,
.vuecal.main-cal .vuecal__header .vuecal__title .day-no
 {
  display: block;
  font-family: 'Anton-Regular';
  font-size: 24px;
}

.vuecal.main-cal .vuecal__header .vuecal__weekdays-headings .vuecal__heading {
  height: auto;
}

.vuecal.main-cal .vuecal__header .vuecal__title-bar {
  background-color: transparent;
}


.vuecal.main-cal .vuecal__header .vuecal__title {
  justify-content: center;
  padding-left: 8px;
}

.vuecal.main-cal .vuecal__header .vuecal__title,
.vuecal.main-cal .vuecal__header .vuecal__title button {
  font-size: 16px;
  color: #2c3e50;
}

.vuecal.main-cal.vuecal--year-view .vuecal__header .vuecal__title {
  display: block;
  font-family: 'Anton-Regular';
  font-size: 24px;
}

.vuecal__no-event {
  display: none;
}

.vuecal__flex .vuecal__header .vuecal__menu {
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}










.vuecal__event-delete {
  /* transform: translateY(0) !important;
  z-index: 1 !important;

  width: 20%;
  right: 0;
  left: initial;
  align-items: center; */

  display: none ;
}

.wrap_cell-date {
  width: 100%;
  height: 100%;
  text-align: right;
  padding: 4px;
  box-sizing: border-box;
}

/* kolorowanie soboty i niedzieli  */
.vuecal__cell--day6,
.vuecal__cell--day7,
.wrap_cell-date.holi-date
 {
  color: #c25a10;
  /* background-color: rgba(235, 235, 235, 0.3); */
  background-color: rgba(250, 144, 82, 0.1);
}

.vuecal--date-picker .vuecal__cell-date.holi-date {
  color: #c25a10;
}

.vuecal--date-picker .vuecal__cell--out-of-scope .vuecal__cell-date.holi-date {
  color: rgba(0,0,0,.25) !important;
}

.vuecal.main-cal .vuecal__cell--selected {
  background-color: rgba(255, 254, 235, 0.3) !important;
}

.vuecal__cell--out-of-scope {
  color: rgba(0,0,0,.25) !important;
}

.vuecal__cell--out-of-scope .wrap_cell-date.holi-date {
  color: rgba(0,0,0,.25) !important;
}

.vuecal--month-view .vuecal__heading:nth-child(6) .weekday-label,
.vuecal--month-view .vuecal__heading:nth-child(7) .weekday-label,
.weekday-label .holi-label {
  /* color: #a81e1e; */
  color: #c25a10;
}

/* widok dzień - kolorowanie soboty i niedzieli  */

.vuecal__title button .Sob,
.vuecal__title button .Nie {
  color: #c25a10;
}

/* widok tydzień - kolorowanie soboty i niedzieli  */
.vuecal__weekdays-headings .vuecal__heading:nth-child(6),
.vuecal__weekdays-headings .vuecal__heading:nth-child(7) {
  color: #c25a10;
}

.main-cal.vuecal--month-view .vuecal__cell {
  height: 120px;
}

.main-cal.vuecal--week-view .vuecal__cell:nth-child(6),
.main-cal.vuecal--week-view .vuecal__cell:nth-child(7) {
  /* background-color: rgba(235, 235, 235, 0.3); */
  background-color: rgba(250, 144, 82, 0.1);
}

.main-cal.vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.vuecal__cell.vuecal__cell--day7

.main-cal.vuecal--month-view .vuecal__cell-date {
  padding: 4px;
}
.main-cal.vuecal--month-view .vuecal__no-event {
  display: none;
}

.vuecal__heading .weekday-label {
  cursor: pointer;
  flex-direction: column;
}

.refr-cal {
  position: absolute;
  top: 4px;
  left: 4px;

}

.refr-cal button {
  color: #2c3e50;
}

.event-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* cursor: pointer; */
  padding: 10px;
  padding-top: 42px;
  position: relative;
  /* border-top: 1px solid rgba(255,255,255,0.5); */
}

.event-wrap .brd {
  position: absolute;
  top: 27px;
  left:0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 2px;
  border: none;
  border-bottom: 1px solid rgba(255,255,255,0.6);
}

.event-wrap.month {
  padding: 0 0 22px 0;
}

.event-wrap.event-min {
  padding: 5px;
  padding-top: 0;
}


.event-wrap.prop {
  padding: 10px 16px;
}


.event-wrap.week.event-drag {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  background-color: red;

}

.event-wrap.week.event-drag.event-drag-v {
  display: block;
}

.event-wrap.week.event-drag-h {
  display: none;
}







.event-wrap .icons {
  position: absolute;
  top: 3px;
  right: 6px;
}

.event-wrap.month .icons {
  position: absolute;
  top: -1px;
}

.event-wrap.prop .icons i {
  font-size: 16px;
}

.i-btn {
  border: none;
  background: transparent;
  cursor: pointer;
}


.event-wrap .icons .i-btn,
.event-wrap .job-time .i-btn
 {
  width: 20px;
  padding: 3px;

}

.copied-cld {
  position: absolute;
  font-size: 10px;
  border: 1px solid #d4d4d4;
  padding: 2px 4px;
  background-color: #d4d4d4;
  border-radius: 6px;
  color: #fff;
  z-index: 100;
}

.copied-cld::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-top-color: #d4d4d4;
  transform: translate(-50%, 0);
}

.copied-cld.copied-active {
  display: block;
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.copied-cld.copied-inactive {
		-webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
}



@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}





.event-wrap.month .icons .i-btn {
  padding: 2px;
  margin-left: 0px;
}
.event-wrap.month .icons .i-btn i {
  font-size: 10px;
}




.d-btn {
  width: 70px;
  background-color: #fff8e3;
  border: 1px solid #ffdb6e;
  cursor: pointer;
  padding: 3px;
  margin: 10px 5px;
  font-family: 'Lato-Regular';

}

.w-70 {
  width: 70px !important;
  }


.d-btn:hover,
.d-btn:active {
 background-color: #faecc3;
}


.event-wrap .job-title {
  position: absolute;
  top: 6px;
  left: 6px;
}

.event-wrap.week .job-title {
    top: 34px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.event-wrap.old-view.week .job-title {
    top: 6px;
    left: 10px;
    right: initial;
    right: unset;
    margin: auto;
}

.event-wrap.month .job-title {
  top: 3px;
}

.event-wrap.day .job-title {
  top: 3px;
}

.event-wrap.event-min .job-title {
  position: relative;
  top: auto;
  top: unset;
  left: auto;
  left: unset;
}

.event-wrap.event-min .job-title .job-no{
  font-size: 13px;
  letter-spacing: initial;
}


.event-wrap .job-title .job-no {
  font-family: 'Lato-Bold';
  font-size: 14px;
  color: #667;
  letter-spacing: .02em;
}

.event-wrap.month .job-title .job-no {
    font-size: 12px;
}

.event-wrap.day .job-title .job-no {
    font-size: 20px;
}

.event-wrap .job-name {
  overflow-wrap: break-word;
  margin-top: 14px;
}

.event-wrap.old-view .job-name {
  margin-top: 4px;
}

.event-wrap .job-clientShort {
  position: absolute;
  top: 4px;
  left: 10px;
  font-size: 14px;
}

.event-wrap .job-clientDay {
  position: absolute;
  top: 4px;
  left: 184px;
  font-size: 18px;
}

.event-wrap .job-name,
.event-wrap .job-client,
.event-wrap .job-no,
.vuecal__cell .vuecal__event * {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.event-wrap.week .job-name.job-name-s,
.event-wrap.prop .job-name.job-name-s {
  font-size: 14px;
}



.event-wrap .job-client {
  font-size: 12px;
  line-height: 24px;
}

.event-wrap.day .job-client {
  font-size: 14px;
  line-height: 26px;
}

.event-wrap.day .job-name {
  font-size: 22px;
}

.event-wrap .job-time:first-child {
  margin-top: 16px;
}

.event-wrap .job-time {
  padding-bottom: 5px;
  font-size: 12px;
}


.event-wrap .job-time strong {
  font-size: 11px;
}

.event-wrap.day .job-time,
.event-wrap.day .job-time strong {
  font-size: 14px;
}

.event-wrap .e-time {
  position: absolute;
  bottom: 4px;
  right: 7px;
  font-size: 12px;
  color: #a3a3a3;
  display: none;
}

.event-wrap .e-time.e-time-show {
  display: block;
}

.event-wrap.prop .btn-wrapper {
  text-align: left;
}

.event-wrap.prop .btn-wrapper .btn-pos {
  padding: 4px 0;

}

.event-wrap.prop .btn-wrapper .btn-pos:not(:first-child) {
  border-top: 1px solid #e8e8e8;

}

.event-wrap.prop .btn-wrapper h5 {
  margin: 0 0 2px 0;
  padding-left: 4px;
}

.event-wrap.prop .btn-wrapper .m-btn {
  padding: 3px 0 3px 16px;
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  color: #2c3e50;
}

.event-wrap.prop .btn-wrapper .m-btn span {
  font-size: 11px;
  display: block;
  padding-left: 20px;
  padding-top: 3px;
}

.event-wrap.prop .btn-wrapper .m-btn:hover,
.event-wrap.prop .btn-wrapper .m-btn:active {
 background-color: #faecc3;
}


.dialog-box {
  position: absolute;
  /* border: 1px solid blue; */
  background-color: #fff8e3;
  width: 300px;
  left: 0px;
  top: 0px;
  z-index: 100;

  -webkit-box-shadow: 5px 5px 9px -3px rgba(164, 165, 183, 1);
  -moz-box-shadow: 5px 5px 9px -3px rgba(164, 165, 183, 1);
  box-shadow: 5px 5px 9px -3px rgba(164, 165, 183, 1);
}

.dialog-box.context-menu {
  width: 164px;
  -webkit-box-shadow: 0px 0px 10px -3px rgb(164 165 183);
  -moz-box-shadow: 0px 0px 10px -3px rgb(164 165 183);
  box-shadow: 0px 0px 10px -3px rgb(164 165 183);
}

.dialog-box.context-menu .d-btn {
  margin: 4px 4px;
  font-size: 12px;
  padding: 2px;
}

.dialog-box.context-menu .event-wrap.prop {
  padding: 13px 0px 6px 0;
}

.dialog-box.context-menu .event-wrap.prop .icons {
  right: 2px;
}

.dialog-box.context-menu .event-wrap.prop .icons i {
  font-size: 14px;
  font-weight: 600;
  color: #999;
}



.main-cal .vuecal__header .vuecal__arrow {
  display: none;
}

/* kolory eventów - klasy z bazy */

/* .main-cal .vuecal__event {
  color: #fff;
}

.main-cal .event-wrap .job-title .job-no {
    color: #fff;
} */



.main-cal .vuecal__event {
  border-right: 1px solid white;
  box-shadow: 0px 3px 16px -5px rgb(120 122 123);
}

.main-cal .month-view .vuecal__event {
  border-top: 1px solid rgba(255, 255, 255, 0.6);

}


/* - nowe - */
/*
.vuecal__event.cl-green,
.ru-jobs-event.cl-green {
  background-color: #cc8c11;
  border-left: 3px solid #7c3c00;
}
.vuecal__event.cl-green .event-wrap.event-drag {
  background-color: #cc8c11;
}
.vuecal__event.cl-green.vuecal__event--focus {
  box-shadow: none;
  background-color: #a46400;
}


.vuecal__event.cl-red,
.ru-jobs-event.cl-red {
  background-color: #0d948c;
  border-left: 3px solid #00443c;
}
.vuecal__event.cl-red .event-wrap.event-drag {
  background-color: #0d948c;
}
.vuecal__event.cl-red.vuecal__event--focus {
  box-shadow: none;
  background-color: #006c64;
}


.vuecal__event.cl-blue,
.ru-jobs-event.cl-blue {
  background-color: #7f11cc;
  border-left: 3px solid #2f007c;
}
.vuecal__event.cl-blue .event-wrap.event-drag {
  background-color: #5700a4;
}
.vuecal__event.cl-blue.vuecal__event--focus {
  box-shadow: none;
  background-color: #7f11cc;
}


.vuecal__event.cl-violet,
.ru-jobs-event.cl-violet {
  background-color: #cc0000;
  border-left: 3px solid #7c0000;
}
.vuecal__event.cl-violet .event-wrap.event-drag {
  background-color: #cc0000;
}
.vuecal__event.cl-violet.vuecal__event--focus {
  box-shadow: none;
  background-color: #a40000;
}

.vuecal__event.cl-pink,
.ru-jobs-event.cl-pink {
  background-color: #cc3c31;
  border-left: 3px solid #7c0000;
}
.vuecal__event.cl-pink .event-wrap.event-drag {
  background-color: #cc3c31;
}
.vuecal__event.cl-pink.vuecal__event--focus {
  box-shadow: none;
  background-color: #a41409;
}

.vuecal__event.cl-yellow,
.ru-jobs-event.cl-yellow {
  background-color: #89940d;
  border-left: 3px solid #394400;
}
.vuecal__event.cl-yellow .event-wrap.event-drag {
  background-color: #89940d;
}
.vuecal__event.cl-yellow.vuecal__event--focus {
  box-shadow: none;
  background-color: #616c00;
}

.vuecal__event.cl-brown,
.ru-jobs-event.cl-brown {
  background-color: #57a010;
  border-left: 3px solid #075000;
}
.vuecal__event.cl-brown .event-wrap.event-drag {
  background-color: #57a010;
}
.vuecal__event.cl-brown.vuecal__event--focus {
  box-shadow: none;
  background-color: #2f7800;
}

.vuecal__event.cl-lime,
.ru-jobs-event.cl-lime {
  background-color: #94660d;
  border-left: 3px solid #441600;
}
.vuecal__event.cl-lime .event-wrap.event-drag {
  background-color: #94660d;
}
.vuecal__event.cl-lime.vuecal__event--focus {
  box-shadow: none;
  background-color: #6c3e00;
}

.vuecal__event.cl-lemon,
.ru-jobs-event.cl-lemon {
  background-color: #165aaf;
  border-left: 3px solid #000a5f;
}
.vuecal__event.cl-lemon .event-wrap.event-drag {
  background-color: #165aaf;
}
.vuecal__event.cl-lemon.vuecal__event--focus {
  box-shadow: none;
  background-color: #003287;
}

.vuecal__event.cl-turquoise,
.ru-jobs-event.cl-turquoise {
  background-color: #ef36bd;
  border-left: 3px solid #9f006d;
}
.vuecal__event.cl-turquoise .event-wrap.event-drag {
  background-color: #ef36bd;
}
.vuecal__event.cl-turquoise.vuecal__event--focus {
  box-shadow: none;
  background-color: #c70e95;
}


.vuecal__event.cl-rasp,
.ru-jobs-event.cl-rasp {
  background-color: #af1665;
  border-left: 3px solid #5f0015;
}
.vuecal__event.cl-rasp .event-wrap.event-drag {
  background-color: #af1665;
}
.vuecal__event.cl-rasp.vuecal__event--focus {
  box-shadow: none;
  background-color: #87003d;
}

.vuecal__event.cl-darkblue,
.ru-jobs-event.cl-darkblue {
  background-color: #ccb600;
  border-left: 3px solid #7c6600;
}
.vuecal__event.cl-darkblue .event-wrap.event-drag {
  background-color: #ccb600;
}
.vuecal__event.cl-darkblue.vuecal__event--focus {
  box-shadow: none;
  background-color: #a48e00;
} */






.vuecal__event.cl-green,
.ru-jobs-event.cl-green {
  background-color: #e8ffee;
  border-left: 3px solid #97fcb2;
}
.vuecal__event.cl-green .event-wrap.event-drag {
  background-color: #e8ffee;
}
.vuecal__event.cl-green.vuecal__event--focus {
  box-shadow: none;
  background-color: #c7fcd5;
}


.vuecal__event.cl-red,
.ru-jobs-event.cl-red {
  background-color: #ffe8e8;
  border-left: 3px solid #fca9a9;
}
.vuecal__event.cl-red .event-wrap.event-drag {
  background-color: #ffe8e8;
}
.vuecal__event.cl-red.vuecal__event--focus {
  box-shadow: none;
  background-color: #ffc9c9;
}


.vuecal__event.cl-blue,
.ru-jobs-event.cl-blue {
  background-color: #e8f0ff;
  border-left: 3px solid #78a5ff;
}
.vuecal__event.cl-blue .event-wrap.event-drag {
  background-color: #e8f0ff;
}
.vuecal__event.cl-blue.vuecal__event--focus {
  box-shadow: none;
  background-color: #d2e0fc;
}


.vuecal__event.cl-violet,
.ru-jobs-event.cl-violet {
  background-color: #eee0ff;
  border-left: 3px solid #bf8aff;
}
.vuecal__event.cl-violet .event-wrap.event-drag {
  background-color: #eee0ff;
}
.vuecal__event.cl-violet.vuecal__event--focus {
  box-shadow: none;
  background-color: #e3ccff;
}

.vuecal__event.cl-pink,
.ru-jobs-event.cl-pink {
  background-color: #ffe6fb;
  border-left: 3px solid #ff8aeb;
}
.vuecal__event.cl-pink .event-wrap.event-drag {
  background-color: #ffe6fb;
}
.vuecal__event.cl-pink.vuecal__event--focus {
  box-shadow: none;
  background-color: #ffccf6;
}

.vuecal__event.cl-yellow,
.ru-jobs-event.cl-yellow {
  background-color: #fff8e3;
  border-left: 3px solid #ffdb6e;
}
.vuecal__event.cl-yellow .event-wrap.event-drag {
  background-color: #fff8e3;
}
.vuecal__event.cl-yellow.vuecal__event--focus {
  box-shadow: none;
  background-color: #fcedbd;
}

.vuecal__event.cl-brown,
.ru-jobs-event.cl-brown {
  background-color: #ffe8d1;
  border-left: 3px solid #c78b50;
}
.vuecal__event.cl-brown .event-wrap.event-drag {
  background-color: #ffe8d1;
}
.vuecal__event.cl-brown.vuecal__event--focus {
  box-shadow: none;
  background-color: #ffdab5;
}

.vuecal__event.cl-lime,
.ru-jobs-event.cl-lime {
  background-color: #dbfea1;
  border-left: 3px solid #91d71a;
}
.vuecal__event.cl-lime .event-wrap.event-drag {
  background-color: #dbfea1;
}
.vuecal__event.cl-lime.vuecal__event--focus {
  box-shadow: none;
  background-color: #ceff7c;
}

.vuecal__event.cl-lemon,
.ru-jobs-event.cl-lemon {
  background-color: #fffd8e;
  border-left: 3px solid #ece800;
}
.vuecal__event.cl-lemon .event-wrap.event-drag {
  background-color: #fffd8e;
}
.vuecal__event.cl-lemon.vuecal__event--focus {
  box-shadow: none;
  background-color: #fffc60;
}

.vuecal__event.cl-turquoise,
.ru-jobs-event.cl-turquoise {
  background-color: #99fffa;
  border-left: 3px solid #11d1c9;
}
.vuecal__event.cl-turquoise .event-wrap.event-drag {
  background-color: #99fffa;
}
.vuecal__event.cl-turquoise.vuecal__event--focus {
  box-shadow: none;
  background-color: #5ef9f2;
}


.vuecal__event.cl-rasp,
.ru-jobs-event.cl-rasp {
  background-color: #ffc9db;
  border-left: 3px solid #ff266e;
}
.vuecal__event.cl-rasp .event-wrap.event-drag {
  background-color: #ffc9db;
}
.vuecal__event.cl-rasp.vuecal__event--focus {
  box-shadow: none;
  background-color: #ffafca;
}

.vuecal__event.cl-darkblue,
.ru-jobs-event.cl-darkblue {
  background-color: #c0d5fd;
  border-left: 3px solid #053695;
}
.vuecal__event.cl-darkblue .event-wrap.event-drag {
  background-color: #c0d5fd;
}
.vuecal__event.cl-darkblue.vuecal__event--focus {
  box-shadow: none;
  background-color: #90b6ff;
}




.vuecal__event.cl-blind,
.ru-jobs-event.cl-blind {
  background-color: #e0e0e0;
  border-left: 3px solid #8c8c8c;
  color: #2c3e50;
}
.vuecal__event.cl-blind .event-wrap.event-drag {
  background-color: #e0e0e0;
}
.vuecal__event.cl-blind.vuecal__event--focus {
  box-shadow: none;
  background-color: #c4c4c4;
}

.vuecal__event.cl-blind .event-wrap .job-title .job-no {
    color: #2c3e50;
}




.color-blind .vuecal__event,
.color-blind .ru-jobs-event {
  background-color: #e0e0e0 !important;
  border-left: 3px solid #8c8c8c !important;
}

.color-blind .vuecal__event .event-wrap.event-drag {
  background-color: #e0e0e0;
}
.color-blind .vuecal__event.vuecal__event--focus {
  box-shadow: none;
  background-color: #c4c4c4;
}

/* .vuecal__event {
  background-color: #e5e5e5 !important;
} */


/* Pozycja labela - widok roczny */

.vuecal--date-picker .vuecal__cell-events-count {
  /* background: #bebebe;
  height: 11px;
  line-height: 11px; */

  width: 4px;
  min-width: 0;
  height: 4px;
  padding: 0;
  color: transparent;
  top: 82%;
  opacity: 0.5;
  font-size: 0;

}

.main-cal .vuecal__cell-events-count {
  top: 68%;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  background: #bebebe;
  padding: 0 6px;
}

.main-cal .year-view .vuecal__cell-events-count {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    padding: 0 16px;
}

.vuecal--date-picker .vuecal__cell-events-count.low {
  /* background: #bebebe; */
  background-color: #99ffd0;
}

.vuecal--date-picker .vuecal__cell-events-count.mid {
  /* background: #a1a1a1; */
  background-color: #42b982;
}

.vuecal--date-picker .vuecal__cell-events-count.high {
  /* background: #757575; */
  background-color: #00703d;
}

/* logo LQT */

/* .logo-lqt {
  position: fixed;
  bottom: 5px;
  right: 10px;
  display: flex;
  width: 180px;
  justify-content: end;
  align-items: center;
  margin-top: 40px;
  display: none;
}

.logo-lqt img {
  width: 44px;
  height: auto;
}

.logo-lqt span {
  font-family: 'Anton-Regular';
  font-size: 24px;
  color: #2c3e50;
} */

/* --- modyfikacja siatki - bordery --- */


.vuecal.main-cal {
      box-shadow: inset 0 0 0 1px rgb(0 0 0 / 15%);
}


.vuecal.main-cal .vuecal__bg {
  margin-bottom: 0px;
  overflow: hidden;
}

.vuecal.main-cal .vuecal__time-cell-line.hours:before,
.vuecal.main-cal .vuecal__cell:before {
  border-color: #d4d4d4;
}

/* .vuecal.main-cal .vuecal__cell:before {
      bottom: -2px;
} */


/* picker - time */

.s-picker-wrapper {
  display: flex;
  margin-top: 72px;
}

.s-picker-wrapper.narrower {
  margin-top: 26px;
}

.s-picker-wrapper>div {
  width: 50%;
}

.s-picker-wrapper>div span {
  display: block;
  text-align: center;
  margin-bottom: 6px;
  font-weight: bold;
}

.s-picker-wrapper .dp__main {
  width: 68%;
}

.s-picker-wrapper .dp__input_wrap {
  display: block;
  width: 100%;

}

.s-picker-wrapper .time-btn-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin: 0 32px;
}

.s-picker-wrapper .time-btn-wrapper .time-btn {
  background: transparent;
  font-size: 24px;
  border: none;
  color: #667;
  cursor: pointer;
}

.s-picker-wrapper .dp-custom-input {
  text-align: center;
  padding-right: 34px;
  border-radius: initial;

}

.dp__overlay_row {
  justify-content: center;

}


.vue-dropdown-item .st-Inactive {
  color: #d9d9d9 !important;
}

.vue-dropdown-item .y-inactive,
.vue-dropdown-item .ft-false {
  color: #999999  !important;
}

.vue-dropdown-item .y-active {
  color: #2c3e50 !important;
}


/*  vue select - job */
dialog .vue-select {
  border-radius:initial;
  border-radius: unset;
  z-index: 2;
  border: 1px solid #e3e3e3;
  background-color: white;
}

dialog .vue-input {
  padding: 10px 6px 12px 6px;
}
dialog .vue-input input {
  background-color: transparent;
}

dialog .vue-input .icon.arrow-downward  {
  border-width: 8px 5px 0;
}

dialog .vue-dropdown-item.highlighted {
    background-color: #fff8e3;
}

dialog .vue-dropdown-item.selected.highlighted {
    background-color: #fceec2;
}

.dialog-box.edit .vue-select {
  width: 100%;

}

.dialog-box.edit .d-btn {
  font-size: 22px;
}

.dialog-box.edit .vue-dropdown {
  max-height: 208px !important;
}

.dialog-box.edit .repeat-content .vue-dropdown {
  max-height: 100px !important;
}

dialog section>div>span {
  display: block;
  text-align: left;

  font-weight: bold;
}

dialog section .s-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

dialog section .light-text {
  font-size: 12px;
  color: #667;
}

dialog section .light-text input {
  vertical-align: middle;
}

dialog section .info-text {
  display: inline-block;
  vertical-align: super;
}

dialog section .no-job-form-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

dialog.dialog-box section .no-job-form-wrapper .vue-select,
dialog.dialog-box section .no-job-form-wrapper textarea {
  width: 48%;
}

dialog.dialog-box section .no-job-form-wrapper textarea {
  font-family: 'Lato-Regular';
  padding-left: 5px;
  border: 1px solid #e3e3e3;
}

dialog.dialog-box section .no-job-form-wrapper textarea:focus {
        outline: none !important;
        border: 1px solid #e3e3e3;
    }


dialog section .job-expired {
  font-size: 14px;
}

dialog section .job-expired span {
  font-size: 12px;
  color: #c25a10;
}

dialog section .job-descr-inf {
  font-size: 12px;
  color: #667;
}

dialog .s-label .info-job-btn  {
  text-decoration: none; 
}

dialog .s-label .info-job-btn i {
  font-size: 10px;
  color: black;
  border: 1px solid #ffdb6e;
  background-color: #fff8e3;
  cursor: pointer;
  padding: 4px 8px;
  margin: 0px 8px;
  min-width: 80px;
  display: inline;
  position: relative;
  top: -2px;
}


/* Repeat content */

dialog .repeat-wrap  {
  margin-top: 50px;
  display: flex;
  align-items: baseline;
  flex-direction: row;
}

dialog .repeat-content  {
  margin-top: 32px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 52px;
}

dialog .repeat-content>span {
  text-align: left;
  font-weight: bold;
}

.dialog-box.edit .repeat-content .vue-select {
  margin-top: 10px;
  width: 76%;
}

/* Dialog box - komunikaty  */

.valid-content {
  position: absolute;
  bottom: 12px;
  left: 10px;
  width: 60%;
  height: 50px;
}

.valid-content {
  font-size: 12px;
  color: red;
}


/* ru-jobs -  ostatnio używane joby */

.ru-wrapper {
  margin-top: 4px;
  position: relative;
  overflow-y: auto;  
}

.firefox .ru-wrapper {

  scrollbar-width: thin; /* Dla przeglądarek obsługujących standardowe właściwości (np. Firefox) */
  scrollbar-color: #d9d9d9 #e9e9e9; /* Kolor paska przewijania i tła (dla Firefox) */
}

/* Stylowanie dla WebKit (Chrome, Safari, nowsze wersje Edge) */
.ru-wrapper::-webkit-scrollbar {
  width: 6px; /* Węższy pasek przewijania */
}

.ru-wrapper::-webkit-scrollbar-track {
  background: #e9e9e9; /* Kolor tła paska przewijania */
}

.ru-wrapper::-webkit-scrollbar-thumb {
  background-color: #d9d9d9; /* Kolor suwaka paska przewijania */
  border-radius: 4px; /* Delikatne zaokrąglenie suwaka */
}

.ru-wrapper::-webkit-scrollbar-thumb:hover {
  background: #bbb; /* Kolor suwaka przy najechaniu */
}



.d-lnk.b-ru-jobs {
  position: absolute;
  top: 297px;
  right: 1px;

    font-size: 10px;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #42b982;
    cursor: pointer;
    padding: 0;
}



.ru-wrapper .ru-jobs {
  opacity: 1.0;

}

.ru-title {
  margin-top: 30px;
  font-weight: bold;
}

.ru-wrapper .ru-jobs-event {
  margin: 5px 0px 5px 0px;
  padding: 5px;
  font-size: 12px;
  cursor: grab;
  overflow-wrap: break-word;
  height: 76px;
  overflow: hidden;
}


.ru-wrapper .ru-jobs.ru-tiny .ru-jobs-event {
  height: 57px;
}

.ru-wrapper .ru-jobs.ru-tiny .ru-jobs-event:last-child {
  display: none;
}

.ru-wrapper .ru-jobs-event strong {
  font-family: 'Lato-Bold';
  color: #667;
  font-weight: normal;
  font-size: 13px;
}





.business-hours {
  background-color: rgba(220, 255, 220, 0.2);
  /* border: 1px solid rgba(255, 210, 0, 0.6); */
}


/* .vuecal__event.vuecal__event--static {
opacity: 1 !important;
} */


/* .drag-state {
  border: 1px solid red !important;
  opacity: 1 !important;
}  */


.total-wrapper {
    width: 100%;
    margin-top: -1px;
    margin-bottom: 10px;
    display: flex;
    padding: 7px 0;
    padding-left: 47px;
    box-sizing: border-box;
    justify-content: center;
    background-color: #efefef;
    border: 1px solid #ccc;
    position: relative;
}

.total-wrapper .inner-total {
    flex-grow: 1;
    border-left: 1px solid #ccc;
    font-size: 14px;
}

.total-wrapper .total-week {
    position: absolute;
    right: 0px;
    font-size: 14px;
    bottom: -1px;
    background-color: #aaa;
    padding: 8px 9px;
    color: white;
}


.total-wrapper .total-week.plug-left {
  right: initial;
  right: unset;
  left: 0;
  height: 17px;
  width: 30px;
}

 .ho-wrap {
      margin-bottom: 4px;
  } 

.ho-btn {
  border: 1px solid #bcbcbc;
    padding: 2px 6px;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    margin-left: -1px;
    background-color: #e1e1e1;
    font-family: 'Lato-Bold';
    /* min-width: 50px; */
    cursor: pointer;
}

.ho-btn:hover {
    background-color: #d1d1d1;
}


.ho-btn.ho-active {
  color: white;
  position: relative;
}

.ho-office.ho-active {
  background-color: steelblue;  
}

.ho-home.ho-active {  
  background-color: #42b982;
}

/* .ho-btn.ho-active::after {
  content: '';
  position: absolute;
  width: 34px;
  height: 2px;
  border-bottom: 1px solid #ccc;
  left: 0;
  bottom: -4px;
  right: 0;
  margin: 0 auto;
} */

/* ----------- MEDIA ----------- */

@media (max-width: 1820px) {

  .event-wrap.week .job-title .job-no {
    font-size: 13px;
    letter-spacing: initial;
  }

  .event-wrap .job-clientShort {
    top: 5px;
    left: 6px;
    font-size: 12px;
  }

}


@media (max-width: 1600px) {

  .mx-2 {
    width: 200px;
  }

  @-webkit-keyframes slide-out-left {
    0% {
      margin-left: 0px;
      opacity: 1;
    }
    100% {
      margin-left: -216px;
      opacity: 0.5;
    }
  }
  @keyframes slide-out-left {
    0% {
      margin-left: 0px;
      opacity: 1;
    }
    100% {
      margin-left: -216px;
      opacity: 0.5;
    }
  }

  @-webkit-keyframes slide-in-left {
    0% {
      margin-left: -216px;
      opacity: 0.5;
    }
    100% {
      margin-left: 0px;
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      margin-left: -216px;
      opacity: 0.5;
    }
    100% {
      margin-left: 0px;
      opacity: 1;
    }
  }

  .event-wrap .brd {
    top: 44px;
  }

  .event-wrap.day .brd {
    top: 30px;
  }

  .vuecal--date-picker.demo {
    width: 200px !important;
  }

  .event-wrap.week {
    padding: 8px;
    padding-top: 52px;
  }

  .event-wrap.week .icons {
    top: 2px;
    right: 7px;
  }

  .event-wrap.month .icons {
    right: 3px;
  }

.event-wrap .icons .i-btn {
    width: 16px;
  }


.event-wrap.week .job-title {
    top: 27px;

}

.event-wrap.old-view.week .job-title {
    top: 27px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.event-wrap .job-clientShort {
  top: 4px;
}

  .event-wrap.month .job-title {
    top: 4px;
    left: 4px;
  }

  .event-wrap.week .job-title .job-no {
    font-size: 12px;
    letter-spacing: initial;
  }

  .event-wrap.month .job-title .job-no {
    font-size: 10px;
    letter-spacing: initial;
  }

  .event-wrap.week .job-name {
    font-size: 12px;
    margin-top: 4px;
  }


  .event-wrap.old-view.week .e-time {
    bottom: initial;
    bottom: unset;
    right: initial;
    right: unset;
    top: 3px;
    left: 5px;
    display: block;
  }


  .ru-wrapper .ru-jobs-event {
    margin: 5px 0px 5px 0px;

  }

}

@media (max-width: 1440px) {


  .total-wrapper .inner-total {
    font-size: 12px;
  }

  .total-wrapper .total-week {
      font-size: 12px;
      padding: 8px 7px;

  }


  .total-wrapper .total-week.plug-left {
    height: 15px;
    width: 34px;
  }


  .event-wrap .job-clientShort {
    display: none;
  }

  .event-wrap.week .job-title {
    top: 6px;
    margin: auto;
    right: initial;
    right: unset;
    left: 4px;
  }

  .event-wrap.week .job-title .job-no {
    font-size: 10px;
  }

  .event-wrap .e-time {

    bottom: initial;
    bottom: unset;
    top: 25px;
    left: 0;
    right: 0;
  }

@media (max-width: 1280px) {

  .event-wrap.week .job-title .job-no {
    font-size: 9px;
  }

  .event-wrap.old-view.week .job-title .job-no {
    font-size: 10px;
  }

}



}


 /* ----dark mode---- */


 .dark-mode #app {
   background-color: #2c3e50;
   color:#d1d1d1;
 }

 .dark-mode .nav-btn,
 .dark-mode .d-btn {
  border: 1px solid #5c5c5c;
  background-color: #2c3e50;
  color:#d1d1d1;

}
.dark-mode .nav-btn:hover,
.dark-mode .d-btn:hover {
  background-color: #1c2630 !important;
}


.dark-mode .nav-btn.view-active,
.dark-mode .d-btn.view-active {
  background-color: #1c2630;
  color: white;
}


.dark-mode .total-wrapper {
    background-color: #1e3245;
    border: 1px solid #3e6487;
    margin-top: -2px;

}

.dark-mode .total-wrapper .inner-total {
    border-left: 1px solid #3e6487;
}

.dark-mode .total-wrapper .total-week {
    background-color: #1c2630;
    color: white;
    border: 1px solid #3e6487;
    padding: 7px 8px;
}

.dark-mode .total-wrapper .total-week.plug-left {
    left: -1px;
    width: 32px;
}

.dark-mode .vuecal.main-cal {
    box-shadow: none;
    border: 1px solid #3e6487;
}

.dark-mode .vuecal.main-cal .vuecal__time-cell-line.hours:before,
.dark-mode .vuecal.main-cal .vuecal__cell:before {
    border-color: #3e6487;
}

.dark-mode .vuecal__cell:before {
    border: 1px solid #3e6487;
}

.dark-mode .main-cal.vuecal--week-view .vuecal__cell:nth-child(6),
.dark-mode .main-cal.vuecal--week-view .vuecal__cell:nth-child(7) {
    background-color: rgba(12, 12, 12, 0.2);
}

.dark-mode .vuecal__cell--day6,
.dark-mode .vuecal__cell--day7,
.dark-mode .wrap_cell-date.holi-date {
    background-color: rgba(12, 12, 12, 0.2);
}

.dark-mode .vuecal.main-cal .vuecal__cell--selected {
    background-color: rgba(28, 38, 0, 0.3) !important;
}

.dark-mode .vuecal__cell--current,
.dark-mode .vuecal__cell--today {
    background-color: rgba(58, 99, 55,.4);
}


.dark-mode .main-cal .vuecal__event {
    filter: brightness(58%);
    color: black;
}

.dark-mode .ru-wrapper .ru-jobs-event {
    filter: brightness(58%);
    color: #2c3e50;
}

.dark-mode .vuecal--date-picker .vuecal__title-bar button {
 color: #d1d1d1;
}

.dark-mode .vuecal__cell--out-of-scope {
  color: rgba(177,177,46,.5) !important;
}

.dark-mode .vuecal--date-picker:not(.vuecal--day-view) .vuecal__cell--selected .vuecal__cell-content {
    background-color: #256e4c;
    color: #fff;
}

.dark-mode .main-cal .vuecal__cell-events-count {
      background: #256e4c;
}

.dark-mode .vue-dropdown {
  border: 1px solid #5c5c5c;
  background-color: #2c3e50;
}

.dark-mode .vue-input input {
  background-color: #2c3e50;
}

.dark-mode .vue-dropdown-item.selected.highlighted,
.dark-mode .vue-dropdown-item.selected,
.dark-mode .vue-dropdown-item.highlighted {
    background-color: #1c2630;
}

.dark-mode .vue-dropdown-item span {
    font-weight: normal;
}

.dark-mode .vue-dropdown::-webkit-scrollbar {
      width: 12px;
}

    /* Track */
.dark-mode .vue-dropdown::-webkit-scrollbar-track {
      background: #2c3e50;
      border: 1px solid #1c2630;
}

    /* Handle */
.dark-mode .vue-dropdown::-webkit-scrollbar-thumb {
      background: #1c2630;
      border: 1px solid #1c2630;
}

    /* Handle on hover */
.dark-mode .vue-dropdown::-webkit-scrollbar-thumb:hover {
      background: black;
}

.dark-mode .dialog-box {
      background-color: #1c2630;
      box-shadow: 0px 3px 16px -5px rgb(0 0 0);
}

.dark-mode .event-wrap.prop {
    background-color: #1c2630 !important;
    border-top: 1px solid #3e6487 !important;
}

.dark-mode .event-wrap.prop .icons i,
.dark-mode header .icons i,
.dark-mode .dialog-box.context-menu .event-wrap.prop .icons i {
    color: #3e6487;
}

.dark-mode .event-wrap .icons .i-btn,
.dark-mode .event-wrap .job-time .i-btn {
    color: #3e6487 !important;
}

.dark-mode header {
  background-color: #1c2630 !important;
    border-bottom: 1px solid #3e6487 !important;
}

.dark-mode dialog section .s-label,
.dark-mode .s-picker-wrapper,
.dark-mode dialog,
.dark-mode .dialog-box,
.dark-mode .event-wrap.prop .btn-wrapper .m-btn {
  color: #d1d1d1;
}

.dark-mode dialog.dialog-box section .no-job-form-wrapper textarea {
  background-color: #212121 !important;
  border: 1px solid #2d2d2d !important;
  color: #d1d1d1;
}


.dark-mode dialog .vue-select {
    background-color: #212121 !important;
    border: 1px solid #212121;
}

.dark-mode .vue-select-header {
    border: 1px solid #2d2d2d !important;
    background-color: #212121 !important;
}

.dark-mode .vue-select-header .vue-input input {
    background-color: #212121;
}

.dark-mode .event-wrap.prop .btn-wrapper .m-btn:hover,
.dark-mode .event-wrap.prop .btn-wrapper .m-btn:active {
    background-color: #3e6487;
}

.dark-mode .event-wrap.prop .btn-wrapper .btn-pos:not(:first-child) {
    border-top: 1px solid #3e6487;
}

.dark-mode .vue-dropdown-item .st-Inactive {
  color: #808080 !important;
}

.dark-mode .vue-dropdown-item .y-active {
  color: #d1d1d1 !important;
}


.dark-mode .refr-cal button {
  color: #d1d1d1;
}

.dark-mode .event-wrap.week.event-drag button:disabled,
.dark-mode .event-wrap.week.event-drag button[disabled=disabled] {
  color: #d1d1d1 !important;
}


.dark-mode .copied-cld {

  border: 1px solid #545454;
  background-color: #545454;
  color: #fff;

}

.dark-mode .copied-cld::before {
  border-top-color: #545454;
}


.dark-mode dialog .s-label .info-job-btn i {
  border: 1px solid #5c5c5c;
  background-color: #2c3e50;
  color: #d1d1d1;
}


/* .vgt-input::placeholder {color: red !important; opacity: 1 !important;} */

</style>
